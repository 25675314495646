import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { AddChartingWatchList, getChartingWatchList } from '../../services/watchListChartingServides';

const AnychartSlideNav = ({setReRenderChart,setStockCode, stockFullDetails }) => {

  const [open, setOpen] = useState(false);
  const [watchList, setWatchList] = useState([])
  const [userDetails, setUserDetails] = useState()
  useEffect(() => {
    let nm = localStorage.getItem('userDetails')
    setUserDetails(JSON.parse(nm))
  }, [])
  const handleAddWatchList = () => {
    let data = {
      userId: userDetails?.userId,
      country: stockFullDetails?.country,
      providerName: "",
      stockId: stockFullDetails?.stockId,
      stockCode: stockFullDetails?.stockCode,
      stockName: stockFullDetails?.stockName
    }
    try {
      AddChartingWatchList(data).then((res) => {
        getWatchlist()
      })
    } catch (error) {

    }
  }

  const getWatchlist=()=>{
    try {
      getChartingWatchList(userDetails?.userId).then((res) => {
        if (res.status == 200) {
          setWatchList(res.data.data)
        }

      })
    } catch (error) {

    }

  }
  useEffect(() => {
    // debugger
    if(userDetails?.userId){
      getWatchlist()
    }
    
  }, [userDetails?.userId])
  const handleChangetrade=(val)=>{
    setReRenderChart(true)
    setStockCode(val.stockCode)
  }
  return (
    <>

      <div style={{ minHeight: '150px' }} className='watchlist_tab_anychart' >
        <Collapse in={open} dimension="width" className="wishlist_open">
          <div class="card card-body">
            <div class="container">
              <div class="row wishlist_head">
                <div class="col-md-7">
                  <div class="dropdown show">

                    <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Watchlist
                    </a>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="icon_view">
                        <i class="bi bi-three-dots"></i>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="icon_view" onClick={handleAddWatchList} >
                        <i class="bi bi-plus-square-dotted"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row whishmmainhead">
                <div class="col-md-3">
                  <div class="menu_sbl">Symbol</div>
                </div>
                <div class="col-md-3">
                  <div class="menu_sbl">Last</div>
                </div>
                <div class="col-md-3">
                  <div class="menu_sbl">Chg</div>
                </div>
                <div class="col-md-3">
                  <div class="menu_sbl">Chg%</div>
                </div>
              </div>

              <div class="list_tbl_sec">
                {
                  watchList.map((val, ind) => {
                    return (
                      <div class="row whishlis" onClick={()=>handleChangetrade(val)} >
                        <div class="col-md-3">
                          <div class="name_oflogo">
                            {/* <span class="icon_50"><img src='https://s3-symbol-logo.tradingview.com/indices/nifty-50.svg' /></span> */}
                            <span class="icon_50_name">{val.stockCode} </span>

                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="rank_1">
                            <span class="rk_1">19411.75</span>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="rank_2">301.15</div>
                        </div>
                        <div class="col-md-3">
                          <div class="rank_3">0.63%</div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div class="togle_button">
        <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
          <Tooltip {...props}>
            Open Right Panel
          </Tooltip>
        )}
          placement="left">
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            <i class="bi bi-menu-down"></i>
          </Button>
        </OverlayTrigger>
      </div>
      {/* <div class="togle_button_bg">  
  <div class="collapse" id="collapseExample">
    <div class="card card-body">
      <div class="container">
        <div class="row wishlist_head">
          <div class="col-md-9">
          <div class="dropdown show">
            <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Watchlist
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
         </div>
          </div>
          <div class="col-md-3">
             <div class="row">
                 <div class="col-md-6">
                   <div class="icon_view">
                     <i class="bi bi-three-dots"></i>
                   </div>
                 </div>
                 <div class="col-md-6">
                 <div class="icon_view">
                     <i class="bi bi-plus-square-dotted"></i>
                   </div>
                 </div>
             </div>
          </div>
        </div>
        <div class="row whishmmainhead">
          <div class="col-md-3">
            <div class="menu_sbl">Symbol</div>
          </div>
          <div class="col-md-3">
            <div class="menu_sbl">Last</div>
          </div>
          <div class="col-md-3">
            <div class="menu_sbl">Chg</div>
          </div>
          <div class="col-md-3">
            <div class="menu_sbl">Chg%</div>
          </div>
        </div>
        <div class="row whishlis">
          <div class="col-md-3">
            <div class="name_oflogo">
              <span class="icon_50"><img src='https://s3-symbol-logo.tradingview.com/indices/nifty-50.svg' /></span>
              <span class="icon_50_name">NIFTY</span>
              <span class="icon_dot"><i class="bi bi-dot"></i></span>
            </div>
          </div>
          <div class="col-md-3">
              <div class="rank_1">
                <span class="rk_1">19411.75</span>
              </div>
          </div>
          <div class="col-md-3">
              <div class="rank_2">301.15</div>
          </div>
          <div class="col-md-3">
              <div class="rank_3">0.63%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="togle_button">
  <p>
  <button class="btn btn-primary" type="button"  data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
    <i class="bi bi-menu-down"></i>
  </button>
  </p>
</div> */}


    </>
  )
}

export default AnychartSlideNav