import React, { useEffect, useState, useMemo } from "react";
import {
  getStockCashFlow,
  getRapidStockCashFlow,
} from "../../../../services/TradeService";
import approximateNumber from "approximate-number";
import NumberConverter from "../../../commonComponent/NumberConverter";
import Loader from "../../../commonComponent/Loader";

const StockCashFlow = ({ typeData, tradeCode, countryCode }) => {
  const [cashFlowData, setCashFlowData] = useState({});
  const [cashFlowDataStatus, setCashFlowDataStatus] = useState(false);
  const [mapedData, setMapedData] = useState([]);
  // useEffect(() => {
  // 	if (typeData === 'CashFlow') {
  // 		try {
  // 			getStockCashFlow(tradeCode).then((res) => {

  // 				setCashFlowData(res?.data?.annualReports||[])
  // 				setCashFlowDataStatus(true)
  // 			})
  // 		} catch (error) {

  // 		}
  // 	}
  // }, [typeData])
  useEffect(() => {
    if (typeData === "CashFlow") {
      try {
        const localName = JSON.parse(localStorage?.getItem("tradeCode"));
        getRapidStockCashFlow(tradeCode || localName).then((res) => {
          // console.log("rapidcash", res?.data);
          setCashFlowData(res?.data || {});
          setCashFlowDataStatus(true);
        });
      } catch (error) {}
    }
  }, [typeData]);
  useEffect(() => {
    if (cashFlowData.length > 0) {
      let a = [];
      for (let i = 0; i < 5; i++) {
        let n = [];
        const result = cashFlowData.filter((arrayData) => {
          if (
            arrayData.fiscalDateEnding ===
            cashFlowData[0].fiscalDateEnding - i
          ) {
            return arrayData;
          }
        });
        for (let j = 0; j < result.length; j++) {
          // if (result[j].fiscalYear === 2023) {
          //

          // 		capital: true,
          // 		decimal: true,
          // 		// round: true
          // 	}))
          // }

          n = {
            fiscalYear: result[j].fiscalDateEnding,
            capitalExpenditures:
              n?.capitalExpenditures || 0 + result[j].capitalExpenditures,
            cashChange: n?.cashChange || 0 + result[j].cashChange,
            cashFlow: n?.cashFlow || 0 + result[j].cashFlow,
            cashFlowFinancing:
              n?.cashFlowFinancing || 0 + result[j].cashFlowFinancing,
            fiscalQuarter: n?.fiscalQuarter || 0 + result[j].fiscalQuarter,
            netBorrowings: n?.netBorrowings || 0 + result[j].netBorrowings,
            netIncome: n?.netIncome || 0 + result[j].netIncome,
          };
        }

        a.push(n);
        //
      }
      setMapedData(a);
    }
  }, [cashFlowData]);

  return (
    <>
      <div
        class="tab-pane fade white_text"
        id="pills-cashflow"
        role="tabpanel"
        aria-labelledby="pills-cashflow-tab"
      >
        <div class="row">
          <div className="d-flex">
            <h4 class="mt-4 mb-4">CashFlow</h4>
            <h6 class="mt-4 mb-4 ml-auto">
              (
              {cashFlowData?.financialCurrency
                ? cashFlowData?.financialCurrency
                : "USD"}
              )
            </h6>
          </div>
          <div class="col-sm-12">
            {cashFlowDataStatus == false ? (
              <Loader />
            ) : Object.keys(cashFlowData)?.length > 0 ? (
              <div class="Fundamentals-bx">
                <div class="table-responsive">
                  <div class="table-input1">
                    <table class="table white_text ">
                      <tbody>
                        <tr>
                          <th scope="row" class="border-end col-lg-3">
                            {/* Financial Year{" "} */}EBITDA
                          </th>
                          <th scope="row" class="border-end col-lg-3">
                            {/* Net Change in Cash */}Total Revenue
                          </th>
                          <th scope="row" class="border-end col-lg-2">
                            {/* Net Income */}Operating Cashflow
                          </th>
                          <th scope="row" class="border-end col-lg-2">
                            {/* Capital Expenditures */}Return On Assets
                          </th>
                          <th scope="row" class="border-end col-lg-2">
                            Free Cash Flow{" "}
                          </th>
                        </tr>
                        <tr>
                          <td>
                            $
                            {cashFlowData?.ebitda?.longFmt
                              ? cashFlowData?.ebitda?.longFmt
                              : 0}
                          </td>
                          <td>
                            $
                            {cashFlowData?.totalRevenue?.longFmt
                              ? cashFlowData?.totalRevenue?.longFmt
                              : 0}
                          </td>
                          <td>
                            $
                            {cashFlowData?.operatingCashflow?.longFmt
                              ? cashFlowData?.operatingCashflow?.longFmt
                              : 0}
                          </td>
                          <td>
                            {cashFlowData?.returnOnAssets?.fmt
                              ? cashFlowData?.returnOnAssets?.fmt
                              : "0.00%"}
                          </td>
                          <td>
                            $
                            {cashFlowData?.freeCashflow?.longFmt
                              ? cashFlowData?.freeCashflow?.longFmt
                              : 0}
                          </td>
                        </tr>
                        {/* {cashFlowData.map((val, ind) => {
                          return (
                            <tr>
                              <td>{val.fiscalDateEnding}</td>
                              <td>
                                ${" "}
                                {Number(
                                  val.changeInExchangeRate
                                ).toLocaleString()}
                              </td>
                              <td>
                                $ {Number(val.netIncome).toLocaleString()}
                              </td>
                              <td>
                                ${" "}
                                {Number(
                                  val.capitalExpenditures
                                ).toLocaleString()}
                              </td>
                              <td>
                                ${" "}
                                {Number(
                                  val.cashflowFromFinancing
                                ).toLocaleString()}
                              </td>
                            </tr>
                          );
                        })} */}

                        {/* <tr>
												 <th scope="row" class="border-end col-lg-4">
													 Financial Year </th>
												 {
													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">FY {val.fiscalYear} </td>
														 )
													 })
												 }
											 </tr>
											 <tr>
												 <th scope="row" class="border-end col-lg-4">Net
													 Change in Cash</th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.cashChange)} </td>
														 )
													 })

												 }
											 </tr>
											 <tr>
												 <th scope="row" class="border-end col-lg-4">Net Income </th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.netIncome)} </td>
														 )
													 })

												 }
											 </tr>

											 <tr>
												 <th scope="row" class="border-end col-lg-4">Capital
													 Expenditures </th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.capitalExpenditures)} </td>
														 )
													 })

												 }
											 </tr>

											 <tr>
												 <th scope="row" class="border-end col-lg-4">Free
													 Cash Flow </th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.cashFlow)} </td>
														 )
													 })

												 }
											 </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-100 text-center white_text">
                <h4 className="no-data white_text">Data Not Available</h4>
              </div>
            )}
          </div>
          {/* <div class="col-sm-12">
            {cashFlowDataStatus == false ? (
              <Loader />
            ) : mapedData.length > 0 ? (
              <div class="Fundamentals-bx">
                <div class="table-responsive">
                  <div class="table-input1">
                    <table class="table white_text ">
                      <tbody>
                        <tr>
                          <th scope="row" class="border-end col-lg-3">
                            Financial Year{" "}
                          </th>
                          <th scope="row" class="border-end col-lg-3">
                            Net Change in Cash
                          </th>
                          <th scope="row" class="border-end col-lg-2">
                            Net Income
                          </th>
                          <th scope="row" class="border-end col-lg-2">
                            Capital Expenditures
                          </th>
                          <th scope="row" class="border-end col-lg-2">
                            Free Cash Flow{" "}
                          </th>
                        </tr>
                        {cashFlowData.map((val, ind) => {
                          return (
                            <tr>
                              <td>{val.fiscalDateEnding}</td>
                              <td>
                                ${" "}
                                {Number(
                                  val.changeInExchangeRate
                                ).toLocaleString()}
                              </td>
                              <td>
                                $ {Number(val.netIncome).toLocaleString()}
                              </td>
                              <td>
                                ${" "}
                                {Number(
                                  val.capitalExpenditures
                                ).toLocaleString()}
                              </td>
                              <td>
                                ${" "}
                                {Number(
                                  val.cashflowFromFinancing
                                ).toLocaleString()}
                              </td>
                            </tr>
                          );
                        })}

                        {/* <tr>
												 <th scope="row" class="border-end col-lg-4">
													 Financial Year </th>
												 {
													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">FY {val.fiscalYear} </td>
														 )
													 })
												 }
											 </tr>
											 <tr>
												 <th scope="row" class="border-end col-lg-4">Net
													 Change in Cash</th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.cashChange)} </td>
														 )
													 })

												 }
											 </tr>
											 <tr>
												 <th scope="row" class="border-end col-lg-4">Net Income </th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.netIncome)} </td>
														 )
													 })

												 }
											 </tr>

											 <tr>
												 <th scope="row" class="border-end col-lg-4">Capital
													 Expenditures </th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.capitalExpenditures)} </td>
														 )
													 })

												 }
											 </tr>

											 <tr>
												 <th scope="row" class="border-end col-lg-4">Free
													 Cash Flow </th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.cashFlow)} </td>
														 )
													 })

												 }
											 </tr> */}
          {/* </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-100 text-center white_text">
                <h4 className="no-data white_text">Data Not Available</h4>
              </div>
            )} */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default StockCashFlow;
