import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
    'Authorization':API_KEY
}


export const gettradeList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTrades?UserId=${data?.id}&pageNumber=${data?.pageNumber}&pageSize=${data?.pageSize}${data.search.length>0?`&searchText=${data.search}`:''}&Country=${data.Country}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const gettradeCountryList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTradeCountry`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getOpentradeCountryList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetOpenTradeCountry?UserId=${data?.id}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getClosetradeList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetCloseTradeList?UserId=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}


export const GetOpenTradeListByUserId = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetOpenTradeList?UserId=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&Country=${data.Country}&portfolioId=${data.portfolioId}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getOpentradeList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetOpenTrades?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&UserId=${data.id}&PortfolioId=${data.portfolioId}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const updateOpenTrade = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/UpdateOpenTrade?transactionNo=${data.transactionNo}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const getOpentradeListFinancelYear = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetUserTrades?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&UserId=${data.id}&PortfolioId=${data.portfolioId}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const deleteTrade = async (id) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/DeleteTrade?id=${id}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}


export const RemoveUserTrade = async (TradeId , UserId) => {
    debugger
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/RemovePendingTrade?TradeId=${TradeId}&UserId=${UserId}`,

            mode: 'cors',
            headers,
            
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const closeOpenTrade = async (data) => {
    try {
        const response = await axios({
            method: 'post',
           // url: `${API_URL}/Trades/CloseTrade`,
            url: `${API_URL}/Trades/CloseTrade?TradeId=${data.TradeId}&CloseWithMyPrice=${data.CloseWithMyPrice}&ClosePrice=${data.ClosePrice}`,

            mode: 'cors',
            headers,
            data: data
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const editTrade = async (data, id) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/UpdateTrade?TradeId=${id}`,
            mode: 'cors',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
                'Authorization': API_KEY
            },
            data: data
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getWatchList = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetWatchList?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&UserId=${data.id}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getorderHistory = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTradeHistory?TransactionNo=${data}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const removeWatchList = async (id) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/DeleteWatchList?id=${id}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const AddWatchList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddWatchList`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getStockDetails = async (data) => {
    let withoutSpaces = data.tradeCode.replace(/\s+/g, '')
    
    let _url = `${API_URL}/IEXStock/GetStockDetails/${withoutSpaces}/${data.stockDate}`;
    if(withoutSpaces.includes("ASX:")){
        _url = `${API_URL}/IEXStock/GetStockDetails/${withoutSpaces}`;
    }
    try {
        const response = await axios({
            method: 'GET',
            url: _url,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getRapidStockDetails = async (data) => {
  let withoutSpaces = data.tradeCode.replace(/\s+/g, "");

  let _url = `${API_URL}/Rapid/GetStockDetails/${withoutSpaces}`;
  if (withoutSpaces.includes("ASX:")) {
    _url = `${API_URL}/Rapid/GetStockDetails/${withoutSpaces}`;
  }
  try {
    const response = await axios({
      method: "GET",
      url: _url,
      mode: "cors",
      headers,
    });
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      alert("Not authorized.");
    } else if (response?.status >= 500) {
      alert("Something went wrong");
    }
  } catch (error) {}
};


export const getStockChartDetails = async () => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockDetails/AAPL/1w`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getStockNews = async (newsDate, tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockNews/${tradeCode}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getRapidStockNews = async (newsDate, tradeCode) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}/Rapid/GetStockNews/${tradeCode}`,
      mode: "cors",
      headers,
      // data: {
      //   firstName: 'Fred',
      //   lastName: 'Flintstone'
      // }
    });
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      alert("Not authorized.");
    } else if (response?.status >= 500) {
      alert("Something went wrong");
    }
  } catch (error) {}
};


export const getStockIncomeStatement = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockIncomeStatement/${tradeCode}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}



export const getStockBalanceSheet = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockBalanceSheet/${tradeCode}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}



export const getStockCashFlow = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockCashFlow/${tradeCode}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getRapidStockCashFlow = async (tradeCode) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}/Rapid/GetStockCashFlow/${tradeCode}`,
      mode: "cors",
      headers,
      // data: {
      //   firstName: 'Fred',
      //   lastName: 'Flintstone'
      // }
    });
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      alert("Not authorized.");
    } else if (response?.status >= 500) {
      alert("Something went wrong");
    }
  } catch (error) {}
};


export const AddOpenTradeAPI = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddOpenTrade`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const modifyOpenTrade = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/ModifyTrade?TradeId=${data.TradeId}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getDevident = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockDividends/${data.tradeCode}/${data.interval}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            // NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const GetStockStats = async (stockCode) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockStats/${stockCode}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getStockOverView = async (stockCode) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetOverview/${stockCode}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const AddChartDraw = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/ChartingLevel/AddChart`,
            mode: 'cors',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
                'Authorization': API_KEY
            },
            data: data
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const UpdateChartDraw = async (data,id) => {
    // 
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/ChartingLevel/UpdateChart?ChartingLevelId=${id}`,
            mode: 'cors',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
                'Authorization': API_KEY
            },
            data: data
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const getXmlsChart = async (id) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/ChartingLevel/GetCharts?UserId=${id}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const deleteXmlsChart = async (id) => {
    // 
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/ChartingLevel/DeleteChart?id=${id}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getXmlsChartData = async (id) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/ChartingLevel/GetChartById?ChartinglevelId=${id}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getJSONChartData = async (path) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `https://api.trading.tradinglounge.com/${path}`,
            mode: 'no-cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getGainerLooser = async (stockCode) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetTopGainersLosers`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getInflationRate = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetInflationRate`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const GetInvestmentReturnWeekly = async (data) => {
    
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetInvestmentReturnWeekly/${data}`,
            mode: 'cors',
            headers,
            
        });
        
       if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const GetInvestmentReturnMonthly = async (data) => {
    
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetInvestmentReturnMonthly/${data}`,
            mode: 'cors',
            headers,
            
        });
        
       if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}