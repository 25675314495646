import React, { useEffect, useState, useReducer } from "react";
import {
  AddWatchList,
  GetOpenTradeListByUserId,
  closeOpenTrade,
  getClosetradeList,
  getOpentradeCountryList,
  getWatchList,
  getorderHistory,
  gettradeCountryList,
  gettradeList,
  removeWatchList,
  updateOpenTrade,
} from "../../services/TradeService";
import moment from "moment/moment";
import DeleteConfirmation from "../commonComponent/DeleteConfirmation";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import {
  getSearchStockDetails,
  getRapidSearchStockDetails,
} from "../../services/HomeService";
import { IoMdArrowRoundUp, IoMdArrowRoundDown } from "react-icons/io";
import { AiOutlineCalculator } from "react-icons/ai";
import Pagination from "../commonComponent/Pagination";
import { Link } from "react-router-dom";
import { IoEyeSharp, IoReaderOutline, IoTimeOutline } from "react-icons/io5";
import ViewImage from "../commonComponent/commonModels/ViewImage";
import {
  getCountryListAPI,
  getExchangeRateAPI,
  getRapidExchangeRateAPI,
} from "../../services/commonServices";
import CountryCurrencyList from "../commonComponent/CountryCurrencyList";
import { getProviders } from "../../services/ProviderServices";
import { toast } from "react-toastify";
import { BsInfoCircle, BsInfoCircleFill } from "react-icons/bs";
import BuySellUpdateTrade from "./Model/BuySellUpdateTrade";
import OrderHistory from "./Model/OrderHistory";
import CloseTrade from "../commonComponent/CloseTrade";
import { RemoveUserTrade } from "../../services/TradeService";
import RemoveTrade from "../commonComponent/RemoveTrade";
const initialopenTradeList = [];
const reducer = (state, action) => {
  switch (action.type) {
    case "COMPLETE":
      return action.data?.map((todo) => {
        // todo.sort((date1, date2) => date2.openDate - date1.openDate);
        return todo;
      });
    default:
      return state;
  }
};
const CustomTooltip = ({ content, position }) => (
  <div
    className="custom-tooltip"
    style={{ top: position.top, left: position.left }}
  >
    <div className="tooltip-content">
      <h6>Actions</h6>
      {content}
    </div>
  </div>
);

const TradeTable = ({
  setTradeDetails,
  allCountryList,
  portfololioList,
  runAgainDashBoard,
  setRunAgainDashBoard,
  setTradeCode,
  setOpenTradeRun,
  setCalculatedData,
  userType,
  setCalculatedTradeData,
}) => {
  const [tradeList, setTradeList] = useState([]);
  const [pendingTradeLoad, setPendingTradeLoad] = useState(false);
  const [openTradeallData, setOpenTradeallData] = useState([]);
  const [watchList, setWatchList] = useState([]);
  const [watchListRun, setWatchListRun] = useState(true);
  const [tradeListRun, setTradeListRun] = useState(true);
  const [deleteConfirmData, setDeleteConfirmData] = useState("");
  const [ConfirmRemoveData, setConfirmRemoveData] = useState("");
  const [tradeToDelete, setTradeToDelete] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [countryShow, setCountryShow] = useState("");
  const [profitLossList, setProfitLossList] = useState([]);
  const [type, setType] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageNumber: 1,
    pageSize: 15,
    totalPages: 0,
  });
  const { pageNumber, pageSize, currentPage, totalPages } = pagination;
  const [paginationClose, setPaginationClose] = useState({
    currentPageClose: 1,
    pageNumberClose: 1,
    pageSizeClose: 15,
    totalPagesClose: 0,
  });
  const { pageNumberClose, pageSizeClose, currentPageClose, totalPagesClose } =
    paginationClose;
  const [paginationTrade, setPaginationTrade] = useState({
    currentPageTrade: 1,
    pageNumberTrade: 1,
    pageSizeTrade: 15,
    totalPagesTrade: 0,
  });
  const { pageNumberTrade, pageSizeTrade, currentPageTrade, totalPagesTrade } =
    paginationTrade;
  const [paginationWatch, setPaginationWatch] = useState({
    currentPageWatch: 1,
    pageNumberWatch: 1,
    pageSizeWatch: 15,
    totalPagesWatch: 0,
  });
  const { pageNumberWatch, pageSizeWatch, currentPageWatch, totalPagesWatch } =
    paginationWatch;
  const [typeShow, setTypeShow] = useState("pendingTrade");
  const [stockDetails, setStockDetails] = useState({});
  const [deleteType, setDeleteType] = useState("");
  const [closeTradeList, setCloseTradeList] = useState([]);
  const [getTradeRun, setGetTraderun] = useState(true);
  const [closetradeListRun, setClosetradeListRun] = useState(false);
  const [showOpenTradeList, setShowOpenTradeList] = useState(false);
  const [portFolipID, setPortFolioID] = useState();
  const [stopLossErr, setStopLossErr] = useState(false);
  const [EditOpenTrade, setEditOpenTrade] = useState(false);
  const [tooltips, setTooltips] = useState({});
  const [buySellTradeData, setBuySellTradeData] = useState({});
  const [orderhistorysymbol, setorderhistorysymbol] = useState([]);
  const [transactionId, setTransactionId] = useState(null);
  const [shouldRefreshData, setShouldRefreshData] = useState(false);
  let selectedPortfolio = localStorage.getItem("portFolioId");
  const [editedDate, setEditedDate] = useState("");
  const [editedEntryPrice, setEditedEntryPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [popUpShow, setPopUpShow] = useState(false);
  const toastQueue = [];
  const showToast = (message) => {
    toastQueue.push(message);
    if (!popUpShow) {
      displayNextToast();
    }
  };

  const displayNextToast = () => {
    if (toastQueue.length > 0) {
      const message = toastQueue.shift();
      toast.error(message, {
        autoClose: 3000, // Set the duration as needed
        // onClose: () => {
        //     setPopUpShow(false);
        //     displayNextToast(); // Display the next toast message
        // },
      });
      setPopUpShow(true);
      setTimeout(() => {
        setPopUpShow(false);
        // displayNextToast();
      }, 3500);
    }
  };

  useEffect(() => {
    setPortFolioID(selectedPortfolio);
  }, [portfololioList.length]);

  const [chartImg, setChartImg] = useState("");
  const handlePageChange = (newPage) => {
    setPagination({ ...pagination, currentPage: currentPage + newPage });
    // Perform data fetching or other actions when page changes
  };
  const handlePageChangeClose = (newPage) => {
    setPaginationClose({
      ...paginationClose,
      currentPageClose: currentPageClose + newPage,
    });
    // Perform data fetching or other actions when page changes
  };
  const handlePageChangeTrade = (newPage) => {
    setPaginationTrade({
      ...paginationTrade,
      currentPageTrade: currentPageTrade + newPage,
    });
    // Perform data fetching or other actions when page changes
  };
  const handlePageChangeWatch = (newPage) => {
    setPaginationWatch({
      ...paginationWatch,
      currentPageWatch: currentPageWatch + newPage,
    });
    // Perform data fetching or other actions when page changes
  };

  const [openTradeList, dispatch] = useReducer(reducer, initialopenTradeList);
  const getPendingTradeList = (Country) => {
    setPendingTradeLoad(true);
    let data = {
      id: userType?.userId,
      pageNumber: currentPageTrade,
      pageSize: pageSizeTrade,
      search: "",
      Country: Country,
    };
    if (data.id !== undefined) {
      try {
        gettradeList(data).then((res) => {
          if (res?.status === 200) {
            setPendingTradeLoad(false);
            setGetTraderun(false);
            setTradeList(res.data.data);
            setPaginationTrade({
              ...paginationTrade,
              totalPagesTrade: res.data.totalPages,
            });
          }
        });
      } catch (error) {}
    }
  };
  useEffect(() => {
    if (countryShow) {
      getPendingTradeList(countryShow);
    }
  }, [
    countryShow,
    currentPageTrade,
    runAgainDashBoard,
    pageNumberTrade,
    pageSizeTrade,
  ]);
  const getCloseTradeListAPI = () => {
    let data = {
      id: userType?.userId,
      pageNumber: currentPageClose,
      pageSize: pageSizeClose,
    };
    if (data.id !== undefined) {
      try {
        getClosetradeList(data).then((res) => {
          if (res?.status === 200) {
            setClosetradeListRun(false);
            let data = res?.data?.data || [];
            for (let i = 0; i < data.length; i++) {
              // debugger
              if (data[i].short == "true") {
                let n = data[i].valueInShares - data[i].closeAmount;
                data[i].profitLoss = n;
              } else {
                let n = data[i].closeAmount - data[i].valueInShares;
                data[i].profitLoss = n;
              }
              setCloseTradeList(data);
            }
            setPaginationClose({
              ...paginationClose,
              totalPagesClose: res.data.totalPages,
            });
            if (countryShow) {
            }
          }
        });
      } catch (error) {}
    }
  };
  useEffect(() => {
    getCloseTradeListAPI();
  }, [closetradeListRun === true, userType, currentPageClose]);

  const getOpenTradeAPI = (Country) => {
    let data = {
      id: userType?.userId,
      pageNumber: currentPage,
      pageSize: pageSize,
      Country: Country,
      portfolioId: portFolipID,
    };
    if (
      data.id !== undefined &&
      data.portfolioId !== undefined &&
      typeShow == "openTrade" &&
      data.Country?.length > 0
    ) {
      try {
        GetOpenTradeListByUserId(data).then((res) => {
          if (res?.status === 200) {
            // console.log(res.data , "opentradedata")

            // let data = res.data
            setPagination({ ...pagination, totalPages: res.data.totalPages });
            setTradeListRun(false);
            addProfitLoass(res.data.data);
            setOpenTradeallData(res.data.data);
          }
        });
      } catch (error) {}
    } else {
      setShowOpenTradeList(true);
      setTradeListRun(false);
    }
  };
  useEffect(() => {
    getOpenTradeAPI(countryShow);
  }, [currentPage, countryShow, typeShow, portFolipID]);

  useEffect(() => {
    if (shouldRefreshData) {
      getOpenTradeAPI(countryShow);
      setShouldRefreshData(false);
    }
  }, [shouldRefreshData, currentPage, countryShow, typeShow, portFolipID]);
  const addProfitLoass = (tradeListData) => {
    // debugger
    let pdata = [];

    if (tradeListData != undefined && tradeListData.length > 0) {
      let filteredCountry = allCountryList.filter(
        (value) => value.key.toUpperCase() == countryShow.toUpperCase()
      );
      // <select className="form-select  mb-4" value={portFolipID} aria-label="form-select-lg example" onChange={(e) => setPortFolioID(Number(e.target.value))}>
      // 												{/* <option >Select Portfolio</option> */}

      // 												{
      // 													portfololioList?.map((val, ind) => {
      // 														return (
      // 															<option key={ind} value={val.portfolioId}>{val.portfolioName} </option>
      // 														)
      // 													})
      // 												}
      // 											</select>
      let filteredCountryPort = portfololioList.filter(
        (val) => val.portfolioId == portFolipID
      );
      // console.log(filteredCountryPort)
      try {
        // getExchangeRateAPI(
        getRapidExchangeRateAPI(
          filteredCountry[0].value,
          filteredCountryPort[0].currency
        ).then((res) => {
          for (let i = 0; i < tradeListData.length; i++) {
            try {
              // getSearchStockDetails(tradeListData[i].stockCode).then((resp) => {
              getRapidSearchStockDetails(tradeListData[i].stockCode).then(
                (resp) => {
                  if (resp?.status === 200) {
                    let aus = tradeListData[i].stockCode.includes("ASX:");
                    let India = tradeListData[i].stockCode.includes(".BSE");
                    if (res?.status === 200) {
                      // console.log(
                      //   "traderesp",
                      //   res?.data?.rates?.[filteredCountryPort[0].currency]
                      // );
                      let exchangeRate =
                        res?.data?.rates?.[filteredCountryPort[0].currency] ||
                        1;

                      if (aus) {
                        // const price = resp.data["close"];
                        const price = resp.data.quotes[0].price;

                        if (tradeListData[i].short == "true") {
                          let openTradeListing = tradeListData[i];
                          let plValue =
                            tradeListData[i]?.valueInShares -
                            (price * exchangeRate || 1) * tradeListData[i]?.qty;
                          openTradeListing.profitLoss = plValue;
                          openTradeListing.cPrice = price;
                          pdata.push({ ...openTradeListing });
                        } else if (tradeListData[i].short == "false") {
                          let openTradeListing = tradeListData[i];
                          let plValue =
                            (price * exchangeRate || 1) *
                              tradeListData[i]?.qty -
                            tradeListData[i]?.valueInShares;
                          openTradeListing.profitLoss = plValue;
                          openTradeListing.cPrice = price;
                          pdata.push({ ...openTradeListing });
                        }
                      } else if (India) {
                        // debugger;
                        // const price = resp.data["close"];
                        const price = resp.data["Global Quote"]["05. price"];
                        //	console.log(resp.data["Global Quote"]["05. price"] ,"iindia price")
                        if (tradeListData[i].short == "true") {
                          let openTradeListing = tradeListData[i];
                          let plValue =
                            tradeListData[i]?.valueInShares -
                            (price * exchangeRate || 1) * tradeListData[i]?.qty;
                          openTradeListing.profitLoss = plValue;
                          openTradeListing.cPrice = price;
                          pdata.push({ ...openTradeListing });
                        } else if (tradeListData[i].short == "false") {
                          let openTradeListing = tradeListData[i];
                          let plValue =
                            (price * exchangeRate || 1) *
                              tradeListData[i]?.qty -
                            tradeListData[i]?.valueInShares;
                          openTradeListing.profitLoss = plValue;
                          openTradeListing.cPrice = price;
                          pdata.push({ ...openTradeListing });
                        }
                      } else {
                        const price = resp.data?.regularMarketPrice?.raw;
                        if (tradeListData[i].short == "true") {
                          let openTradeListing = tradeListData[i];
                          let plValue =
                            tradeListData[i]?.valueInShares -
                            (price * exchangeRate || 1) * tradeListData[i]?.qty;
                          openTradeListing.profitLoss = plValue;
                          openTradeListing.cPrice = price;
                          pdata.push({ ...openTradeListing });
                        } else if (tradeListData[i].short == "false") {
                          let openTradeListing = tradeListData[i];
                          let plValue =
                            (price * exchangeRate || 1) *
                              tradeListData[i]?.qty -
                            tradeListData[i]?.valueInShares;
                          openTradeListing.profitLoss = plValue;
                          openTradeListing.cPrice = price;
                          pdata.push({ ...openTradeListing });
                        }
                      }
                    } else {
                      let openTradeListing = tradeListData[i];
                      let plValue = "NA";
                      openTradeListing.profitLoss = plValue;
                      openTradeListing.cPrice = "NA";
                      pdata.push({ ...openTradeListing });
                    }
                    pdata.sort(
                      (date1, date2) =>
                        date2.transactionNo - date1.transactionNo
                    );
                    dispatch({ type: "COMPLETE", data: pdata });
                  }
                }
              );
            } catch (error) {}
          }
        });
      } catch (error) {}
    } else {
      dispatch({ type: "COMPLETE", data: [] });
    }
    setShowOpenTradeList(true);
  };

  const handleDeleteTrade = (data) => {
    console.log(data,"data")
    try {
      closeOpenTrade(data).then((res) => {
        if (res?.status === 200) {
          getOpenTradeAPI(countryShow);
          setTradeListRun(true);
          setProfitLossList([]);
          setDeleteConfirmData("");
          // setRunAgainDashBoard(runAgainDashBoard+1)
        }
      });
    } catch (error) {}
  };
  const handleRemoveWatchListConfirm = () => {
    try {
      removeWatchList(deleteConfirmData).then((res) => {
        if (res?.status === 200) {
          setWatchListRun(true);
        }
      });
    } catch (error) {}
  };
  const deleteConfirm = () => {
    if (deleteType == "openTrade") {
      handleDeleteTrade();
    } else if (deleteType == "watchList") {
      handleRemoveWatchListConfirm();
    } else if (deleteType == "PendingTrade") {
      handleRemoveTradeState();
    }
  };
  const [removeTrade, setRemoveTrade] = useState(false);
  const handleRemoveTradeState = () => {
    setRemoveTrade(true);
    handleRemoveTrade();
  };
  const deleteConfirmClose = (data) => {
    if (deleteType == "openTrade") {
      handleDeleteTrade(data);
    } else if (deleteType == "watchList") {
      handleRemoveWatchListConfirm();
    }
  };
  const deleteConfirmRemove = (data) => {
    if (deleteType == "PendingTrade") {
      handleRemoveTrade(data);
    }
  };
  const handleCloseTrade = (valtransaction, valstopprice) => {
    // console.log(valtransaction ,valstopprice ,"valtransaction ,valstopprice")

    setShowModal(true);
    setDeleteConfirmData({
      transactionNo: valtransaction,
      stopLossPrice: valstopprice,
    });
    setType("Close This Open Trade");
    setDeleteType("openTrade");
    // handleSaveAndClose(val)
  };

  useEffect(() => {
    let data = {
      id: userType?.userId,
      pageNumber: currentPageWatch,
      pageSize: pageSizeWatch,
    };

    if (data.id > 0) {
      try {
        getWatchList(data).then((res) => {
          setWatchListRun(false);
          if (res?.status === 200) {
            setWatchList(res.data.data);
            setPaginationWatch({
              ...paginationWatch,
              totalPagesWatch: res.data.totalPages,
            });
          }
        });
      } catch (error) {}
    }
  }, [
    watchListRun && watchListRun == true,
    userType?.userId,
    currentPageWatch,
  ]);

  // const handleOrderHistory = (transactionId) => {
  // 	console.log(transactionId , "orderhistoryid")
  // 	try {
  // 	  getorderHistory(transactionId).then((res) => {
  // 		if (res?.status === 200) {
  // 		  console.log(res.data, "orderhistory");
  // 		  // Set the order history state or do something with the data
  // 		  setorderHistory(res.data);
  // 		}
  // 	  });
  // 	} catch (error) {
  // 	  // Handle error
  // 	}
  //   };

  // const handleOrderHistory = (transactionId) => {
  // 	console.log(transactionId, "transactionId")
  // 	setorderHistory(transactionId)
  // }

  const handleOrderHistory = (id) => {
    // console.log(id, "transactionId");
    setTransactionId(id);
  };

  const handleAddWatchList = (value) => {
    // console.log(value , "value")
    let data = {
      userId: userType?.userId,
      country: value.country,
      providerName: value.providerName,
      stockId: value.stockId,
      stockCode: value.stockCode,
      stockName: value.stockCode,
    };

    setLoading(true);
    try {
      AddWatchList(data)
        .then((res) => {
          if (res?.status === 200) {
            setWatchListRun(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  function getWatchListId(stockCode) {
    // console.log("watchlist", watchList);
    const watchListItem = watchList.find((item) =>
      item.stockCode.includes(stockCode)
    );
    // console.log("watchlist2", watchListItem.watchListId, watchListItem);
    return watchListItem ? watchListItem.watchListId : null;
  }
  const handleRemoveWatchList = (id) => {
    setDeleteConfirmData(id);
    setDeleteType("watchList");
    setType("Remove This WatchList");
  };
  const nm = tradeList?.map((val, i) => {
    return watchList.some((value) => val.stockCode.includes(value.stockCode));
  });

  const handleAddOpenTradeData = (value) => {
    setOpenTradeRun(true);

    setCalculatedData({
      portFolioId: value.portfolioId,
      country: value.country,
      margin: value.margin,
      entry: value.entry,
      stockCode: value.stockCode,
      stockName: value.stockName,
      quantity: value.price / value.entry,
      currentPrice: value.price,
      long: value.direction,
      share: "",
      stopLoss: value.stopLoss,
      cfd: "",
      target: "",
      cummission: "",
      reward: "",
      rewardPercent: "",
      providerName: value.providerName,
      tradingSystemId: value.tradingSystemId,
      tradeId: value.tradeId,
      providerId: value?.providerID,
    });
  };

  const handleAddCalculatorData = (value) => {
    // console.log(value)
    setOpenTradeRun(true);
    setCalculatedTradeData({
      providerId: value?.providerID,
      portFolioId: value.portfolioId,
      country: value.country,
      margin: value.margin,
      entry: value.entry,
      stockCode: value.stockCode,
      stockName: value.stockName,
      quantity: value.price / value.entry,
      tradingSystemId: value.tradingSystemId,
      currentPrice: value.price,
      long: value.direction,
      target: value?.target,
      share: "",
      stopLoss: value.stopLoss,
      cfd: "",
      cummission: "",
      reward: "",
      rewardPercent: "",
      providerName: value.providerName,
      tradeId: value.tradeId,
    });
  };
  useEffect(() => {
    // setCountryList(res.data)
  }, [userType?.userId]);
  const tradeCountryList = () => {
    if (Number(userType?.userId) > 0) {
      let data = {
        id: userType?.userId,
      };
      try {
        gettradeCountryList(data).then((res) => {
          if (res?.status === 200) {
            // console.log(res.data, "countryyy");
            setCountryList(res.data);
            if (res.data.length > 0) {
              setCountryShow(res.data[0]);
              getPendingTradeList(res.data[0]);
            }
          }
        });
      } catch (error) {}
    }
  };
  const handleChangeCountry = (value) => {
    setGetTraderun(true);
    setCountryShow(value.toUpperCase());
    if (typeShow == "pendingTrade") {
      setPaginationTrade({ ...paginationTrade, currentPageTrade: 1 });
    } else if (typeShow == "openTrade") {
      setPagination({ ...pagination, currentPage: 1 });
    }
  };
  const OpenTradeCountryList = () => {
    if (Number(userType?.userId) > 0) {
      dispatch({ type: "COMPLETE", data: [] });
      let data = {
        id: userType?.userId,
      };
      try {
        getOpentradeCountryList(data).then((res) => {
          if (res?.status === 200) {
            // console.log(res.data, "opentradecountrylist");
            var filtered = res.data.filter(function (el) {
              return el !== null && el !== "";
            });
            // console.log(filtered, "filtered");
            setCountryList(filtered);
            if (res.data.length > 0) {
              setCountryShow(res.data[0]);
              getOpenTradeAPI(res.data[0]);
            }
          }
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    tradeCountryList();
  }, [userType.userId]);

  const handleChangeTypeOfShow = (typeValue) => {
    setCountryShow("");

    if (typeValue == "openTrade" && typeShow != typeValue) {
      setTradeList([]);
      dispatch({ type: "COMPLETE", data: [] });
      OpenTradeCountryList();
    } else if (typeValue == "pendingTrade" && typeShow != typeValue) {
      setTradeList([]);
      dispatch({ type: "COMPLETE", data: [] });
      tradeCountryList();
    } else if (typeValue == "closeTrade" && typeShow != typeValue) {
      setTradeList([]);
      dispatch({ type: "COMPLETE", data: [] });
      getCloseTradeListAPI();
      setCountryList([]);
    } else if (typeValue == "watchList" && typeShow != typeValue) {
      setTradeList([]);
      dispatch({ type: "COMPLETE", data: [] });
      setCountryList([]);
    }
    setTypeShow(typeValue);
    setPagination({ ...pagination, currentPage: 1 });
  };
  // openTradeList
  const [nulldata, setNulldata] = useState(1);
  useEffect(() => {
    setNulldata(nulldata + 1);
  }, [
    profitLossList.length !== openTradeList.length,
    openTradeList,
    profitLossList,
  ]);
  const handleUpdateStock = (value) => {
    // console.log(value, "value buysell")
    // debugger
    if (!EditOpenTrade) {
      setStockDetails(value);
      setEditOpenTrade(true);
      setStopLossErr(false);
      setEditedDate(moment(value.openDate).format("YYYY-MM-DD"));
      setEditedEntryPrice(value.transactionPrice);
    }
  };
  const handleChangeStockInput = (e) => {
    if (Number(e.target.value) >= 0 || e.target.value == ".") {
      var roundedNumber = e.target.value;
      var decimalIndex = roundedNumber.indexOf(".");
      if (decimalIndex !== -1) {
        var decimalPlaces = roundedNumber.length - decimalIndex - 1;
        if (decimalPlaces > 2) {
          roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
        }
      }
      let n = e.target.value.split(".");
      if (stockDetails?.short == "false") {
        setStopLossErr(false);
      
        if (e.target.name === "stopLossPrice") {
          if (Number(roundedNumber) > Number(editedEntryPrice)) {
              showToast("For Long Stop can't be more than Entry Price");
            return;
          }
        }
        if (n.length == 2) {
          if (n[0].length <= 10) {
            setStockDetails({
              ...stockDetails,
              [e.target.name]: roundedNumber,
            });
          }
        } else if (e.target.value.length <= 10) {
          setStockDetails({ ...stockDetails, [e.target.name]: roundedNumber });
        }
      } else {
            if (e.target.name === "stopLossPrice") {
              if (Number(roundedNumber) < Number(editedEntryPrice)) {
                showToast("For Short Stop can't be less than Entry Price");
                return;
              }
            }
        if (n.length == 2) {
         
          if (n[0].length <= 10) {
            setStockDetails({
              ...stockDetails,
              [e.target.name]: roundedNumber,
            });
          }
        } else if (e.target.value.length <= 10) {
          setStockDetails({ ...stockDetails, [e.target.name]: roundedNumber });
        }
        setStopLossErr(false);
      }
    }
  };
  const handledatechange = (e) => {
    if (e.target.name === "editedDate") {
      setEditedDate(e.target.value);
    } else if (e.target.name === "editedEntryPrice") {
      setEditedEntryPrice(e.target.value);
    }
  };
  const handleChangeOpenTradeBlurInput = (e) => {
    // if (stockDetails?.short == 'true') {
    // 	if (stockDetails?.transactionPrice >= Number(e.target.value)) {
    // 		setStopLossErr(true)
    // 		toast.error('Set higher stoploss price than entry while short trading')
    // 	}
    // }
  };
  const handleSaveUpdate = () => {
    // console.log(editedEntryPrice ,"editedentryprice")
    if (
      stockDetails.stopLossPrice >= 0 &&
      !stopLossErr &&
      editedDate !== "" &&
      editedEntryPrice !== ""
    ) {
      try {
        const parseentryPrice = parseFloat(editedEntryPrice);
        const updatedStockDetails = {
          ...stockDetails,
          openDate: moment(editedDate).format("YYYY-MM-DD"),
          // openDate: moment(editedDate).toDate(),
          // entryPrice: parseFloat(((editedEntryPrice))),
          transactionPrice: parseentryPrice.toString(),
        };
       
        updateOpenTrade(updatedStockDetails).then((res) => {
          if (res?.status === 200) {
            setStockDetails({});
            setEditOpenTrade(false);
            getOpenTradeAPI(countryShow);
          }
        });
      } catch (error) {}
    }
  };
  const handleSelectTradeView = (val) => {
    setTradeCode(val.stockCode);
    setTradeDetails(val);
  };

  const handleMouseEnter = (id, event) => {
    setTooltips({
      ...tooltips,
      [id]: { top: event.clientY, left: event.clientX },
    });
  };

  const handleMouseLeave = () => {
    setTooltips({});
  };
  const CustomTooltip = ({ content, position }) => (
    <div
      className="custom-tooltip"
      style={{ top: position.top, left: position.left }}
    >
      <div className="tooltip-content">
        <h6>Actions</h6>
        {content}
      </div>
    </div>
  );
  const handleBuySellEdit = (value) => {
    setBuySellTradeData(value);
  };

  const handleDataUpdate = () => {
    setShouldRefreshData(true);
  };

  // const handleRemovePendingTrade = (data ) => {
  // 	debugger
  // 	 console.log(data ,"handleRemovePendingTrade")

  // 	setShowModal(true)
  // 	setDeleteConfirmData(data?.tradeId ,userType?.userId)
  // 	setType('Remove This Pending Trade')
  // 	setDeleteType('PendingTrade')

  // 	//  deleteConfirmPending(data)
  // 	// deleteConfirm()
  // 	// handleSaveAndClose(val)

  // }
  const handleRemovePendingTrade = (val) => {
    setShowModal(true);
    setConfirmRemoveData(val);
    setDeleteType("PendingTrade");
    setType("Remove This Pending Trade");
  };

  const handleRemoveTrade = async (data) => {
    // console.log(data ,"data from removetrade")
    // console.log(data ,"handleremovedata")

    //data?.userId = userType?.userId,
    // console.log(data.tradeId,"data" )
    try {
      await RemoveUserTrade(data?.tradeId, userType?.userId).then((res) => {
        if (res?.status === 200) {
          getOpenTradeAPI(countryShow);
          setTradeListRun(true);
          setProfitLossList([]);
          setConfirmRemoveData("");
          setRunAgainDashBoard(runAgainDashBoard + 1);
        }
      });
    } catch (error) {}
  };

  return (
    <>
      <div class="row">
        <section>
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="postion-bx-min mt-4">
                  {countryList.length > 0 ? (
                    countryList?.map((value, index) => {
                      return (
                        <span
                          key={index}
                          class={
                            countryShow?.toUpperCase() === value.toUpperCase()
                              ? "frm-hd-t me-1 pe-auto"
                              : " frm-hd-t me-1 country-Active pe-auto"
                          }
                          onClick={() => handleChangeCountry(value)}
                        >
                          {value}
                        </span>
                      );
                    })
                  ) : (
                    <span className=" me-1 pe-auto"></span>
                  )}
                  <div
                    class="postion-frm mt-1 table-input1"
                    style={{ "border-top": "1px solid #cbd0dd" }}
                  >
                    {/* <span class="padding-ds">Pending Positions</span>
                                        <span class="padding-ds">Open Positions</span>
                                        <span class="padding-ds">Watchlist</span> */}
                    <div class="col-sm-12 tabs-sec">
                      <ul
                        class="nav nav-pills nav-justified"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li
                          onClick={() => handleChangeTypeOfShow("pendingTrade")}
                        >
                          <button
                            class="nav-link  color-thumb active"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="false"
                          >
                            <span>Pending Positions</span>
                          </button>
                        </li>
                        <li onClick={() => handleChangeTypeOfShow("openTrade")}>
                          <button
                            class="nav-link  color-thumb"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            <span>Open Positions</span>
                          </button>
                        </li>
                        <li
                          onClick={() => handleChangeTypeOfShow("closeTrade")}
                        >
                          <button
                            class="nav-link color-thumb "
                            id="closed-pills-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#closed-pills"
                            type="button"
                            role="tab"
                            aria-controls="closed-pills"
                            aria-selected="false"
                          >
                            <span>Closed Positions</span>
                          </button>
                        </li>
                        <li onClick={() => handleChangeTypeOfShow("watchList")}>
                          <button
                            class="nav-link color-thumb"
                            id="pills-contact-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-contact"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                          >
                            <span>Watchlist</span>
                          </button>
                        </li>
                        {typeShow == "openTrade" && (
                          <li className="ms-auto me-4">
                            <select
                              className="form-select  mb-4"
                              value={portFolipID}
                              aria-label="form-select-lg example"
                              onChange={(e) =>
                                setPortFolioID(Number(e.target.value))
                              }
                            >
                              {/* <option >Select Portfolio</option> */}

                              {portfololioList?.map((val, ind) => {
                                return (
                                  <option key={ind} value={val.portfolioId}>
                                    {val.portfolioName}{" "}
                                  </option>
                                );
                              })}
                            </select>
                          </li>
                        )}
                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div class="table-responsive text-center">
                            <table class="table table-bordered text-center">
                              <thead>
                                <tr>
                                  <th scope="col" className="color-grey">
                                    Date
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Code
                                  </th>
                                  {/* <th scope="col" className='color-grey'>Strategy</th> */}
                                  <th scope="col" className="color-grey">
                                    Direction
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Entry
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Stop
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Target{" "}
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Strategy
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Actions
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Chart
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Calculate
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Trade
                                  </th>
                                  {/* <th scope="col" className='color-grey'>Edit</th>
																	<th scope="col" className='color-grey'>Delete</th> */}
                                  <th scope="col" className="color-grey">
                                    View
                                  </th>

                                  <th scope="col" className="color-grey">
                                    Watchlist
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Delete
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {pendingTradeLoad == false &&
                                  tradeList?.map((val, ind) => {
                                    let symboleData;
                                    let filteredCountry = allCountryList.filter(
                                      (value) =>
                                        value.key.toUpperCase() ==
                                        val.country.toUpperCase()
                                    );
                                    if (filteredCountry.length > 0) {
                                      symboleData = CountryCurrencyList.filter(
                                        (value) =>
                                          value.code == filteredCountry[0].value
                                      );
                                    }
                                    return (
                                      countryShow?.toUpperCase() ===
                                        val?.country?.toUpperCase() && (
                                        <tr key={ind}>
                                          <th scope="row">
                                            {moment(val?.tradeDate).format(
                                              "DD MMM YYYY"
                                            )}
                                          </th>
                                          <td>{val.stockCode}</td>
                                          {/* <td>test</td> */}
                                          <td>
                                            {val.direction === true
                                              ? "Long"
                                              : "Short"}
                                          </td>
                                          <td>
                                            {symboleData[0]?.symbol_native}{" "}
                                            {val?.entry?.toFixed(2)}
                                          </td>
                                          <td>
                                            {symboleData[0]?.symbol_native}{" "}
                                            {val?.stopLoss?.toFixed(2)}
                                          </td>
                                          <td>
                                            {symboleData[0]?.symbol_native}{" "}
                                            {val?.target?.toFixed(2)}
                                          </td>
                                          <td>{val?.tradingSystems?.name}</td>
                                          <td>{val?.action}</td>
                                          <td>
                                            <button
                                              class="btn padding-btn-dtd open-btn w-100"
                                              data-bs-toggle="modal"
                                              title="View Image"
                                              data-bs-target="#viewImage"
                                              onClick={() =>
                                                setChartImg(val.chartFile)
                                              }
                                            >
                                              {" "}
                                              <img
                                                src="assets/image/folder.png"
                                                class="img-fluid"
                                                alt=""
                                              />{" "}
                                            </button>{" "}
                                          </td>
                                          {/* <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100"> <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td> */}
                                          <td>
                                            <button
                                              class="btn padding-btn-dtd open-trade"
                                              onClick={() =>
                                                handleAddCalculatorData(val)
                                              }
                                              title="Calculate This"
                                              data-bs-toggle="modal"
                                              data-bs-target="#calculator"
                                            >
                                              {" "}
                                              <AiOutlineCalculator />{" "}
                                            </button>{" "}
                                          </td>
                                          <td>
                                            <button
                                              class="btn padding-btn-dtd open-trade"
                                              onClick={() =>
                                                handleAddOpenTradeData(val)
                                              }
                                              title="Trade This"
                                              data-bs-toggle="modal"
                                              data-bs-target="#openTrade"
                                            >
                                              {" "}
                                              <MdOutlineCreateNewFolder />{" "}
                                            </button>{" "}
                                          </td>
                                          {/* <td><button class="btn padding-btn-dtd edit-btn w-100" title='Edit' data-bs-toggle="modal" data-bs-target="#createTrade" onClick={() => setTradeData(val)}> <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td> */}
                                          {/* <td><button class="btn padding-btn-dtd delete-btn w-100" title='Delete' data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => setDeleteConfirmData(val.tradeId)}> <img src="assets/image/delete.png" class="img-fluid" alt='' /> </button> </td> */}
                                          <td>
                                            <button
                                              class="btn padding-btn-dtd open-btn w-100"
                                              data-bs-toggle="modal"
                                              title="View Chart"
                                              data-bs-target="#StockView"
                                              onClick={() =>
                                                handleSelectTradeView(val)
                                              }
                                            >
                                              {" "}
                                              <img
                                                src="assets/image/folder.png"
                                                class="img-fluid"
                                                alt=""
                                              />{" "}
                                            </button>{" "}
                                          </td>

                                          {/* {
																					watchList.length > 0 ? watchList.some(value => val.stockCode.includes(value.stockCode)) === true ? <td><button class="btn padding-btn-dtd watch w-100" title='WatchList'> <img src="assets/image/watchlist.png" class="img-fluid" alt='' /></button> </td> :
																						<td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>
																						: <td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>
																				} */}
                                          {watchList.length > 0 ? (
                                            watchList.some((value) =>
                                              val.stockCode.includes(
                                                value.stockCode
                                              )
                                            ) === true ? (
                                              <td>
                                                <button
                                                  class="btn padding-btn-dtd watch w-100"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#deleteModel"
                                                  title="WatchList"
                                                  onClick={() =>
                                                    handleRemoveWatchList(
                                                      getWatchListId(
                                                        val.stockCode
                                                      )
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src="assets/image/watchlist.png"
                                                    class="img-fluid"
                                                    alt=""
                                                  />
                                                </button>
                                              </td>
                                            ) : (
                                              <td>
                                                <button
                                                  class="btn padding-btn-dtd watch w-100"
                                                  title="Add to WatchList"
                                                  onClick={() =>
                                                    handleAddWatchList(val)
                                                  }
                                                  disabled={loading}
                                                >
                                                  <img
                                                    src="assets/image/watch.png"
                                                    class="img-fluid"
                                                    alt=""
                                                  />
                                                </button>
                                              </td>
                                            )
                                          ) : (
                                            <td>
                                              <button
                                                class="btn padding-btn-dtd watch w-100"
                                                title="Add to WatchList"
                                                onClick={() =>
                                                  handleAddWatchList(val)
                                                }
                                                disabled={loading}
                                              >
                                                <img
                                                  src="assets/image/watch.png"
                                                  class="img-fluid"
                                                  alt=""
                                                />
                                              </button>
                                            </td>
                                          )}

                                          <td>
                                            <button
                                              class="btn padding-btn-dtd delete-btn w-100 delete-admin"
                                              title="Delete"
                                              data-bs-toggle="modal"
                                              data-bs-target="#RemoveModel"
                                              onClick={() =>
                                                handleRemovePendingTrade(val)
                                              }
                                            >
                                              {" "}
                                              <i class="bi bi-trash3-fill"></i>{" "}
                                            </button>{" "}
                                          </td>

                                          {/* <Link to={'/chart'} state={{stockCode:val.stockCode}}></Link> */}
                                        </tr>
                                      )
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-12 text-center">
                            {pendingTradeLoad ? (
                              <div class="container-fluid">
                                <div class="container">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="preloader1">
                                        {/* <div class="loader loader-inner-1"> */}
                                        <div class="loader loader-inner-2">
                                          <div class="loader loader-inner-3"></div>
                                        </div>
                                        {/* </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <Pagination
                                currentPage={currentPageTrade}
                                totalPages={totalPagesTrade}
                                onPageChange={handlePageChangeTrade}
                                setPagination={setPaginationTrade}
                                pageNumber={pageNumberTrade}
                                pageSize={pageSizeTrade}
                                pagination={paginationTrade}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          class="tab-pane fade "
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          {typeShow == "openTrade" && (
                            <div class="table-responsive text-center">
                              <table class="table table-bordered text-center">
                                <thead>
                                  <tr>
                                    <th scope="col" className="color-grey">
                                      Date
                                    </th>
                                    <th scope="col" className="color-grey">
                                      Code
                                    </th>
                                    {/* <th scope="col" className='color-grey'>Strategy</th> */}
                                    <th scope="col" className="color-grey">
                                      Direction
                                    </th>
                                    <th scope="col" className="color-grey">
                                      Entry Price
                                    </th>
                                    <th scope="col" className="color-grey">
                                      Current Price
                                    </th>
                                    <th scope="col" className="color-grey">
                                      Stocks
                                    </th>
                                    <th scope="col" className="color-grey">
                                      Amount
                                    </th>
                                    <th scope="col" className="color-grey">
                                      Stop
                                    </th>
                                    <th scope="col" className="color-grey">
                                      P/L
                                    </th>
                                    <th scope="col" className="color-grey">
                                      Edit
                                    </th>
                                    <th scope="col" className="color-grey">
                                      Buy/Sell
                                    </th>
                                    <th scope="col" className="color-grey">
                                      Close
                                    </th>
                                    <th scope="col" className="color-grey">
                                      {" "}
                                      <IoEyeSharp title="View" size={18} />{" "}
                                    </th>
                                    <th scope="col" className="color-grey">
                                      {" "}
                                      <img
                                        src="assets/image/watch.png"
                                        class="img-fluid size"
                                        alt=""
                                        title="WatchList"
                                      />{" "}
                                    </th>
                                    <th scope="col" className="color-grey">
                                      {" "}
                                      <img
                                        src="assets/image/orderhistory.png"
                                        class="img-fluid size"
                                        alt=""
                                        title="Order History"
                                      />{" "}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {showOpenTradeList &&
                                    openTradeallData.length ==
                                      openTradeList.length &&
                                    openTradeList?.map((val, ind) => {
                                      let symboleData;
                                      let filteredCountry =
                                        allCountryList.filter(
                                          (value) =>
                                            value.key.toUpperCase() ==
                                            val.country.toUpperCase()
                                        );
                                      // debugger;
                                      if (filteredCountry.length > 0) {
                                        symboleData =
                                          CountryCurrencyList.filter(
                                            (value) =>
                                              value.code ==
                                              filteredCountry[0].value
                                          );
                                        // console.log(
                                        //   symboleData,
                                        //   "sumboldata open trade"
                                        // );
                                      }
                                      {
                                        // console.log(val, "opentrde val");
                                      }
                                      return (
                                        countryShow?.toUpperCase() ===
                                          val.country.toUpperCase() &&
                                        val.isOpen == true && (
                                          <tr key={ind}>
                                            {/* {console.log(
                                              val.cPrice,
                                              "value of opentrade"
                                            )} */}
                                            {/* <th scope="row">{moment(val.openDate).format('DD MMM YYYY')}</th> */}
                                            <th>
                                              {EditOpenTrade &&
                                              stockDetails?.transactionNo ===
                                                val.transactionNo ? (
                                                <input
                                                  type="date"
                                                  className="know-commper-bx-min w-100"
                                                  name="editedDate"
                                                  value={editedDate}
                                                  onChange={handledatechange}
                                                />
                                              ) : (
                                                moment(val.openDate).format(
                                                  "DD-MM-YYYY"
                                                )
                                              )}
                                            </th>
                                            <td>
                                              {val.stockCode}{" "}
                                              {val?.action?.length > 0 && (
                                                <a
                                                  className={"validation_msg "}
                                                  onMouseEnter={(e) =>
                                                    handleMouseEnter(
                                                      val.transactionNo,
                                                      e
                                                    )
                                                  }
                                                  onMouseLeave={
                                                    handleMouseLeave
                                                  }
                                                >
                                                  <BsInfoCircleFill />
                                                  {val.name}
                                                  {tooltips[
                                                    val.transactionNo
                                                  ] && (
                                                    <CustomTooltip
                                                      content={val.action}
                                                      position={
                                                        tooltips[
                                                          val.transactionNo
                                                        ]
                                                      }
                                                    />
                                                  )}
                                                </a>
                                              )}
                                            </td>
                                            {/* <td>test</td> */}
                                            <td>
                                              {val.short === "false"
                                                ? "Long"
                                                : "Short"}
                                            </td>

                                            <td>
                                              {/* {console.log(val ,"val.stockCountrySymbol")} */}
                                              {EditOpenTrade &&
                                              stockDetails?.transactionNo ===
                                                val.transactionNo ? (
                                                <input
                                                  type="text"
                                                  className={
                                                    EditOpenTrade && stopLossErr
                                                      ? "know-commper-bx-min input-err-color w-100"
                                                      : "know-commper-bx-min w-100"
                                                  }
                                                  name="editedEntryPrice"
                                                  value={editedEntryPrice}
                                                  onChange={handledatechange}
                                                  onBlur={
                                                    handleChangeOpenTradeBlurInput
                                                  }
                                                />
                                              ) : (
                                                `${
                                                  symboleData[0]?.symbol_native
                                                } ${val.transactionPrice.toFixed(
                                                  2
                                                )}`
                                              )}
                                            </td>

                                            {/* <td>{symboleData[0]?.symbol_native} {val.transactionPrice.toFixed(2)}</td> */}
                                            <td>
                                              {symboleData[0]?.symbol_native}{" "}
                                              {Number(val.cPrice).toFixed(2)}
                                            </td>
                                            <td> {val.qty}</td>
                                            <td>
                                              {val?.portfolio?.currencySymbol}{" "}
                                              {val.valueInShares.toFixed(2)}
                                            </td>
                                            {EditOpenTrade &&
                                            stockDetails?.transactionNo ==
                                              val.transactionNo ? (
                                              <td class="text-center">
                                                <input
                                                  className={
                                                    EditOpenTrade == true &&
                                                    stopLossErr
                                                      ? "know-commper-bx-min input-err-color w-100"
                                                      : "know-commper-bx-min w-100"
                                                  }
                                                  name="stopLossPrice"
                                                  value={
                                                    stockDetails.stopLossPrice
                                                  }
                                                  onChange={
                                                    handleChangeStockInput
                                                  }
                                                  onBlur={
                                                    handleChangeOpenTradeBlurInput
                                                  }
                                                />
                                              </td>
                                            ) : (
                                              <td>
                                                {symboleData[0]?.symbol_native}{" "}
                                                {val.stopLossPrice.toFixed(2)}
                                              </td>
                                            )}

                                            <td>
                                              {val?.portfolio?.currencySymbol}{" "}
                                              {val.profitLoss < 0 ? (
                                                <>
                                                  {Number(
                                                    val.profitLoss
                                                  ).toFixed(2)}{" "}
                                                  <span
                                                    style={{
                                                      color: "red",
                                                      "font-size": "14px;",
                                                    }}
                                                  >
                                                    <IoMdArrowRoundDown />
                                                  </span>{" "}
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  {Number(
                                                    val.profitLoss
                                                  )?.toFixed(2)}{" "}
                                                  <span
                                                    style={{
                                                      color: "green",
                                                      "font-size": "14px;",
                                                    }}
                                                  >
                                                    <IoMdArrowRoundUp />
                                                  </span>
                                                </>
                                              )}
                                            </td>
                                            {/* {
																					profitLossList.length>0&&profitLossList?.map((value, ind) => {
																						return (
																							value.tid === val.transactionNo &&
																							<td key={ind}>$ {value.plValue < 0 ? <>{(value.plValue).toFixed(2)} <span style={{ 'color': 'red', 'font-size': '14px;' }}><IoMdArrowRoundDown /></span> </> : <> {(value.plValue)?.toFixed(2)} <span style={{ color: 'green', 'font-size': '14px;' }}><IoMdArrowRoundUp /></span></>}</td>
																							// :<td></td>
																						)
																					})
																				} */}
                                            {/* <td><button class="btn padding-btn-dtd edit-btn w-100" title='Edit'> <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td> */}
                                            {EditOpenTrade &&
                                            stockDetails?.transactionNo ==
                                              val.transactionNo ? (
                                              <td>
                                                <button
                                                  class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100"
                                                  title="Edit"
                                                  onClick={() =>
                                                    handleSaveUpdate()
                                                  }
                                                >
                                                  {" "}
                                                  Save{" "}
                                                </button>{" "}
                                              </td>
                                            ) : (
                                              <td>
                                                <button
                                                  class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100"
                                                  title="Edit"
                                                  onClick={() =>
                                                    handleUpdateStock(val)
                                                  }
                                                >
                                                  {" "}
                                                  <img
                                                    src="assets/image/edit.png"
                                                    class="img-fluid"
                                                    alt=""
                                                  />{" "}
                                                </button>{" "}
                                              </td>
                                            )}
                                            <td>
                                              <button
                                                class="btn padding-btn-dtd delete-btn w-100"
                                                style={{ padding: "0px" }}
                                                title="Close Trade"
                                                data-bs-toggle="modal"
                                                data-bs-target="#BuySellUpdateTrade"
                                                onClick={() =>
                                                  handleBuySellEdit(val)
                                                }
                                              >
                                                {" "}
                                                <img
                                                  src="assets/image/buySell.png"
                                                  style={{
                                                    "max-width": "34px",
                                                  }}
                                                  class="img-fluid"
                                                  alt=""
                                                />{" "}
                                              </button>{" "}
                                            </td>
                                            <td>
                                              <button
                                                class="btn padding-btn-dtd delete-btn w-100"
                                                title="Close Trade"
                                                data-bs-toggle="modal"
                                                data-bs-target="#closeTrade"
                                                onClick={() =>
                                                  handleCloseTrade(
                                                    val.transactionNo,
                                                    val.stopLossPrice
                                                  )
                                                }
                                              >
                                                {" "}
                                                <img
                                                  src="assets/image/delete.png"
                                                  class="img-fluid"
                                                  alt=""
                                                />{" "}
                                              </button>{" "}
                                            </td>

                                            {/* <td><button class="btn padding-btn-dtd delete-btn w-100"  onClick={() => handleCloseTrade(val.transactionNo)}> <img src="assets/image/delete.png" class="img-fluid" alt='' /> </button> </td> */}

                                            <td>
                                              <button
                                                class="btn padding-btn-dtd open-btn w-100"
                                                data-bs-toggle="modal"
                                                title="View Chart"
                                                data-bs-target="#StockView"
                                                onClick={() =>
                                                  handleSelectTradeView(val)
                                                }
                                              >
                                                {" "}
                                                <img
                                                  src="assets/image/folder.png"
                                                  class="img-fluid"
                                                  alt=""
                                                />{" "}
                                              </button>{" "}
                                            </td>
                                            {/* {
																						watchList.length > 0 ? watchList.some(value => val.stockCode.includes(value.stockCode)) === true ? <td><button class="btn padding-btn-dtd watch w-100" title='WatchList'> <img src="assets/image/watchlist.png" class="img-fluid" alt='' /></button> </td> :
																							<td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>


																							: <td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>


																					} */}
                                            {watchList.length > 0 ? (
                                              watchList.some((value) =>
                                                val.stockCode.includes(
                                                  value.stockCode
                                                )
                                              ) === true ? (
                                                <td>
                                                  <button
                                                    class="btn padding-btn-dtd watch w-100"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#deleteModel"
                                                    title="WatchList"
                                                    onClick={() =>
                                                      handleRemoveWatchList(
                                                        getWatchListId(
                                                          val.stockCode
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src="assets/image/watchlist.png"
                                                      class="img-fluid"
                                                      alt=""
                                                    />
                                                  </button>
                                                </td>
                                              ) : (
                                                <td>
                                                  <button
                                                    class="btn padding-btn-dtd watch w-100"
                                                    title="Add to WatchList"
                                                    onClick={() =>
                                                      handleAddWatchList(val)
                                                    }
                                                    disabled={loading}
                                                  >
                                                    <img
                                                      src="assets/image/watch.png"
                                                      class="img-fluid"
                                                      alt=""
                                                    />
                                                  </button>
                                                </td>
                                              )
                                            ) : (
                                              <td>
                                                <button
                                                  class="btn padding-btn-dtd watch w-100"
                                                  title="Add to WatchList"
                                                  onClick={() =>
                                                    handleAddWatchList(val)
                                                  }
                                                  disabled={loading}
                                                >
                                                  <img
                                                    src="assets/image/watch.png"
                                                    class="img-fluid"
                                                    alt=""
                                                  />
                                                </button>
                                              </td>
                                            )}
                                            {/* <Link to={'/chart'} state={{stockCode:val.stockCode}}></Link> */}

                                            <button
                                              className="btn padding-btn-dtd watch w-100"
                                              data-bs-toggle="modal"
                                              title="View Chart"
                                              data-bs-target="#OrderHistory"
                                              onClick={() =>
                                                handleOrderHistory(
                                                  val.transactionNo
                                                )
                                              }
                                            >
                                              <img
                                                src="assets/image/orderhistory.png"
                                                className="img-fluid"
                                                alt=""
                                              />
                                            </button>
                                          </tr>
                                        )
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          )}
                          <div className="col-md-12 text-center">
                            {showOpenTradeList &&
                            openTradeallData.length == openTradeList.length ? (
                              <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                                setPagination={setPagination}
                                pageNumber={pageNumber}
                                pageSize={pageSize}
                                pagination={pagination}
                              />
                            ) : (
                              <div class="container-fluid">
                                <div class="container">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="preloader1">
                                        {/* <div class="loader loader-inner-1"> */}
                                        <div class=" loader-inner-2">
                                          <div class="loader loader-inner-3"></div>
                                        </div>
                                        {/* </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          class="tab-pane fade  "
                          id="closed-pills"
                          role="tabpanel"
                          aria-labelledby="closed-pills-tab"
                        >
                          <div class="table-responsive text-center">
                            <table class="table table-bordered text-center">
                              <thead>
                                <tr>
                                  <th scope="col " className="color-grey">
                                    Date
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Code
                                  </th>
                                  {/* <th scope="col">Strategy</th> */}
                                  <th scope="col" className="color-grey">
                                    Direction
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Entry
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Stop
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Trade Amount
                                  </th>
                                  {/* <th scope="col">Target </th> */}
                                  {/* <th scope="col">Actions</th> */}
                                  {/* <th scope="col">Status</th> */}
                                  {/* <th scope="col"> Pending Position</th> */}
                                  <th scope="col" className="color-grey">
                                    Profit/Loss $
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Profit/Loss %
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Total Amount
                                  </th>
                                  {/* <th scope="col">Close</th> */}
                                  <th scope="col" className="color-grey">
                                    View
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Watchlist
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {closeTradeList?.map((val, ind) => {
                                  let symboleData = [];
                                  let filteredCountry = allCountryList.filter(
                                    (value) =>
                                      value.key.toUpperCase() ==
                                      val.country.toUpperCase()
                                  );
                                  if (filteredCountry.length > 0) {
                                    symboleData = CountryCurrencyList.filter(
                                      (value) =>
                                        value.code == filteredCountry[0].value
                                    );

                                    {
                                      /* setorderhistorysymbol(symboleData[0]?.symbol_native) */
                                    }
                                  }
                                  return (
                                    // countryShow.toUpperCase() === val.portfolio.country.toUpperCase()&&
                                    <tr key={ind}>
                                      <th scope="row">
                                        {moment(val.openDate).format(
                                          "DD MMM YYYY"
                                        )}
                                      </th>
                                      <td>{val.stockCode}</td>
                                      {/* <td>test</td> */}
                                      <td>
                                        {val.short === "false"
                                          ? "Long"
                                          : "Short"}
                                      </td>
                                      <td>
                                        {symboleData[0]?.symbol_native}{" "}
                                        {val.transactionPrice.toFixed(2)}
                                      </td>
                                      <td>
                                        {symboleData[0]?.symbol_native}{" "}
                                        {val.stopLossPrice.toFixed(2)}
                                      </td>
                                      <td>
                                        {symboleData[0]?.symbol_native}{" "}
                                        {val.valueInShares.toFixed(2)}
                                      </td>
                                      {/* <td>{val.target}</td> */}
                                      {/* <td>test</td> */}
                                      {/* <td>{val.isOpen ? "Open" : "Closed"}</td> */}
                                      {/* {
																					profitLossList?.map((value,ind)=>{
																						return(
																							value.tid===val.transactionNo&&
																							<td>{symboleData[0]?.symbol_native} {value.plValue < 0 ? <>{(value.plValue).toFixed(2)} <span style={{ 'color': 'red', 'font-size': '14px;' }}><IoMdArrowRoundDown /></span> </> : <> {(value.plValue)?.toFixed(2)} <span style={{ color: 'green', 'font-size': '14px;' }}><IoMdArrowRoundUp /></span></>}</td>
																						)
																					})
																				} */}
                                      <td>
                                        {symboleData[0]?.symbol_native}{" "}
                                        <span
                                          style={
                                            val.profitLoss > 0
                                              ? { color: "green" }
                                              : { color: "red" }
                                          }
                                        >
                                          {val.profitLoss.toFixed(2)}{" "}
                                        </span>{" "}
                                      </td>
                                      <td>
                                        <span
                                          style={
                                            val.profitLoss > 0
                                              ? { color: "green" }
                                              : { color: "red" }
                                          }
                                        >
                                          {(
                                            (val.profitLoss * 100) /
                                            val.valueInShares
                                          ).toFixed(3)}
                                          %
                                        </span>{" "}
                                      </td>
                                      <td>
                                        {symboleData[0]?.symbol_native}{" "}
                                        {Number(
                                          val.valueInShares + val.profitLoss
                                        ).toFixed(2)}
                                      </td>
                                      {/* <td><button class="btn padding-btn-dtd edit-btn w-100" title='Edit'> <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td> */}
                                      {/* <td><button class="btn padding-btn-dtd delete-btn w-100" title='Close Trade' data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleCloseTrade(val.transactionNo)}> <img src="assets/image/delete.png" class="img-fluid" alt='' /> </button> </td> */}
                                      <td>
                                        <button
                                          class="btn padding-btn-dtd open-btn w-100"
                                          data-bs-toggle="modal"
                                          title="View Chart"
                                          data-bs-target="#StockView"
                                          onClick={() =>
                                            handleSelectTradeView(val)
                                          }
                                        >
                                          {" "}
                                          <img
                                            src="assets/image/folder.png"
                                            class="img-fluid"
                                            alt=""
                                          />{" "}
                                        </button>{" "}
                                      </td>
                                      {/* {
																					watchList.length > 0 ? watchList.some(value => val.stockCode.includes(value.stockCode)) === true ? <td><button class="btn padding-btn-dtd watch w-100" title='WatchList'> <img src="assets/image/watchlist.png" class="img-fluid" alt='' /></button> </td> :
																						<td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>


																						: <td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>


																				} */}

                                      {watchList.length > 0 ? (
                                        watchList.some((value) =>
                                          val.stockCode.includes(
                                            value.stockCode
                                          )
                                        ) === true ? (
                                          <td>
                                            <button
                                              class="btn padding-btn-dtd watch w-100"
                                              data-bs-toggle="modal"
                                              data-bs-target="#deleteModel"
                                              title="WatchList"
                                              onClick={() =>
                                                handleRemoveWatchList(
                                                  getWatchListId(val.stockCode)
                                                )
                                              }
                                            >
                                              <img
                                                src="assets/image/watchlist.png"
                                                class="img-fluid"
                                                alt=""
                                              />
                                            </button>
                                          </td>
                                        ) : (
                                          <td>
                                            <button
                                              class="btn padding-btn-dtd watch w-100"
                                              title="Add to WatchList"
                                              onClick={() =>
                                                handleAddWatchList(val)
                                              }
                                              disabled={loading}
                                            >
                                              <img
                                                src="assets/image/watch.png"
                                                class="img-fluid"
                                                alt=""
                                              />
                                            </button>
                                          </td>
                                        )
                                      ) : (
                                        <td>
                                          <button
                                            class="btn padding-btn-dtd watch w-100"
                                            title="Add to WatchList"
                                            onClick={() =>
                                              handleAddWatchList(val)
                                            }
                                            disabled={loading}
                                          >
                                            <img
                                              src="assets/image/watch.png"
                                              class="img-fluid"
                                              alt=""
                                            />
                                          </button>
                                        </td>
                                      )}
                                      {/* <Link to={'/chart'} state={{stockCode:val.stockCode}}></Link> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div class="col-md-12 text-center">
                            <Pagination
                              currentPage={currentPageClose}
                              totalPages={totalPagesClose}
                              onPageChange={handlePageChangeClose}
                              setPagination={setPaginationClose}
                              pageNumber={pageNumberClose}
                              pageSize={pageSizeClose}
                              pagination={paginationClose}
                            />
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="pills-contact"
                          role="tabpanel"
                          aria-labelledby="pills-contact-tab"
                        >
                          <div class="table-responsive text-center">
                            <table class="table table-bordered text-center">
                              <thead>
                                <tr>
                                  <th scope="col" className="color-grey">
                                    Date
                                  </th>
                                  <th scope="col" className="color-grey">
                                    stock Name
                                  </th>
                                  <th scope="col" className="color-grey">
                                    stock Code
                                  </th>

                                  <th scope="col" className="color-grey">
                                    View
                                  </th>
                                  <th scope="col" className="color-grey">
                                    Delete
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {watchList?.map((val, ind) => {
                                  return (
                                    // countryShow.toUpperCase() === val.country.toUpperCase() &&
                                    <tr key={ind}>
                                      <th scope="row">
                                        {moment(val.createdDate).format(
                                          "DD MMM YYYY"
                                        )}{" "}
                                      </th>
                                      <td>{val.stockName}</td>
                                      <td>{val.stockCode}</td>
                                      <td>
                                        <button
                                          class="btn padding-btn-dtd open-btn w-100"
                                          title="View Chart"
                                          data-bs-toggle="modal"
                                          data-bs-target="#StockView"
                                          onClick={() =>
                                            setTradeCode(val.stockCode)
                                          }
                                        >
                                          {" "}
                                          <img
                                            src="assets/image/folder.png"
                                            class="img-fluid"
                                            alt=""
                                          />{" "}
                                        </button>{" "}
                                      </td>
                                      {/* <td><button class="btn padding-btn-dtd delete-btn w-100" title='Delete' onClick={() => handleRemoveWatchList(val.watchListId)}> <img src="assets/image/delete.png" class="img-fluid" alt='' /> </button> </td> */}
                                      <td>
                                        <button
                                          class="btn padding-btn-dtd delete-admin"
                                          title="Delete"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteModel"
                                          onClick={() =>
                                            handleRemoveWatchList(
                                              val.watchListId
                                            )
                                          }
                                        >
                                          {" "}
                                          <i class="bi bi-trash3-fill"></i>{" "}
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-12 text-center">
                            <Pagination
                              currentPage={currentPageWatch}
                              totalPages={totalPagesWatch}
                              onPageChange={handlePageChangeWatch}
                              setPagination={setPaginationWatch}
                              pageNumber={pageNumberWatch}
                              pageSize={pageSizeWatch}
                              pagination={paginationWatch}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Loader/> */}
      </div>
      <ViewImage chartImg={chartImg} />
      <CloseTrade
        deleteConfirmClose={deleteConfirmClose}
        type={type}
        deleteConfirmData={deleteConfirmData}
      />
      <RemoveTrade
        deleteConfirmRemove={deleteConfirmRemove}
        type={type}
        ConfirmRemoveData={ConfirmRemoveData}
      />
      <DeleteConfirmation deleteConfirm={deleteConfirm} type={type} />
      <BuySellUpdateTrade
        onSave={handleDataUpdate}
        userType={userType}
        allCountryList={allCountryList}
        setBuySellTradeData={setBuySellTradeData}
        buySellTradeData={buySellTradeData}
        portFolipID={portFolipID}
      />
      <OrderHistory
        transactionId={transactionId}
        setorderhistorysymbol={setorderhistorysymbol}
        orderhistorysymbol={orderhistorysymbol}
      />

      {/* <EditTrade setTradeListRun={setTradeListRun} setTradeData={setTradeData} tradeData={tradeData} portfololioList={portfololioList} providerList={providerList} /> */}
    </>
  );
};

export default TradeTable;
