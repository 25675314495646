import React, { useEffect, useState } from 'react'
import { GetProviderCummission, addProvider, deleteProviders, getFeedBack, DeleteFeedBack, getProviders, updateProviders, GetHelpVideos, deleteHelpVideoAPI } from '../../services/ProviderServices'
import AddUpdateModel from './Model/AddUpdateModel'
import ProviderModel from './Model/ProviderModel'
import AddCummission from './Model/AddCummission'
import { deleteStrategy, getStrategy, getTradeSystemBYID } from '../../services/StrategyService'
import AddEditStrategy from './Model/AddEditStrategy'
import DeleteConfirmation from '../commonComponent/DeleteConfirmation'
import { editTrade, gettradeCountryList, gettradeList } from '../../services/TradeService'
import moment from 'moment/moment'
import CreateTrade from './Model/CreateTrade'
import Pagination from '../commonComponent/Pagination'
import TradeModel from '../Trade/Model/TradeModel'
// import EditTrade from './Model/EditTrade'
import { deleteTrade } from '../../services/TradeService'
import CummissionDetails from '../commonComponent/commonModels/CummissionDetails'
import { BiSearchAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom'
import ViewImage from '../commonComponent/commonModels/ViewImage'
import { getCountryListAPI } from '../../services/commonServices'
import CountryCurrencyList from '../commonComponent/CountryCurrencyList'
import { toast } from 'react-toastify'
import AddEditVideo from './Model/AddEditVideo'

const AdminTable = ({ userType }) => {
    const [addUpdateSelect, setAddUpdateSelect] = useState('')

    const [strategyList, setStrategyList] = useState([])
    const [getProviderRun, serGetProviderRun] = useState(true)
    const [providerDataList, setPrividerDataList] = useState([])
    const [inputCummission, setInputCummission] = useState({ providerId: '', aboveTransValue: '', upToTransValue: '', commission: '', comments: '', type: 'Fixed', createdBy: '', modifiedBy: '' })
    const [inputData, setInputData] = useState({ providerName: '', parentCompany: '', yearAsCFDProvider: '', platform: '' })
    const [showSearchInput, setShowSearchInput] = useState(false)
    const [deleteConfirmData, setDeleteConfirmData] = useState('')
    const [editTrue, setEditTrue] = useState(false)
    const [runAgainStrategyAPI, setRunAgainStrategyAPI] = useState(true)
    const [strategyInput, setSerategyInput] = useState({ reRender: 0, UserId: '1', Name: '', Notes: '', EntryStrategy: '', ExitStrategy: '', ScaleInOutStrategy: '', reRender: 0 })
    const [pagination, setPagination] = useState({ currentPage: 1, pageNumber: 1, pageSize: 15, totalPages: 0 })
    const [search, setSearch] = useState('')
    const { pageNumber, pageSize, currentPage, totalPages } = pagination
    const [paginationStrategy, setPaginationStrategy] = useState({ currentPageStrategy: 1, pageNumberStrategy: 1, pageSizeStrategy: 15, totalPagesStrategy: 0 })
    const { pageNumberStrategy, pageSizeStrategy, currentPageStrategy, totalPagesStrategy } = pagination
    const [feedbackpagination, setfeedbackPagination] = useState({ feedbackcurrentPage: 1, feedbackpageNumber: 1, feedbackpageSize: 15, feedbacktotalPages: 0, feedbacksearch: '' })
    const { feedbackpageNumber, feedbackpageSize, feedbackcurrentPage, feedbacktotalPages, feedbacksearch } = feedbackpagination
    const [tradeList, setTradeList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [countryShow, setCountryShow] = useState('')
    const [tradeData, setTradeData] = useState('')
    const [tradeCode, setTradeCode] = useState('')
    const [deleteType, setDeleteType] = useState('')
    const [tradeListRun, setTradeListRun] = useState(true)
    const [deleteMsg, setDeleteMsg] = useState('')
    const [providerCummission, setProviderCummission] = useState([])
    const [getTradeRun, setGetTraderun] = useState(true)
    const [getfeedback, setgetfeedback] = useState([])
    const [getfeedbackRun, serGetfeedbackRun] = useState(true)
    const [actionEdit, setActionEdit] = useState(false)
    const [editTradeLineData, setEditTradeLineData] = useState({})
    const [chartImg, setChartImg] = useState('')
    const [allCountryList, setAllCountryList] = useState([])
    const [tradeDetails, setTradeDetails] = useState({})
    const [deleteConfirmCommi, setDeleteConfirmCommi] = useState(false)
    const [editerror, setEditError] = useState({ entryErr: false, tragerErr: false, stoplossErr: false })
    const { entryErr, tragerErr, stoplossErr } = editerror
    const [helpVideopagination, setHelpVideoPagination] = useState({ helpVideocurrentPage: 1, helpVideopageNumber: 1, helpVideopageSize: 15, helpVideototalPages: 0, helpVideosearch: '' })
    const { helpVideopageNumber, helpVideopageSize, helpVideocurrentPage, helpVideototalPages, helpVideosearch } = helpVideopagination
    const [helpVideoList, setHelpVideoList] = useState([])
    const [addUpdateVideoSelect, setAddUpdateVideoSelect] = useState('add')
    const [inputVideo, setInputVideo] = useState({ userId: '', moduleNeme: '', helpNeme: '', description: '', videoFile: '', reRenderV: 1 })
    useEffect(() => {
        let data = {
            PageNumber: 1,
            PageSize: 1000
        }
        try {
            getProviders(data).then((res) => {
                setPrividerDataList(res.data.data)
            })
        } catch (error) {

        }
        serGetProviderRun(false)
    }, [getProviderRun === true])


    useEffect(() => {
        try {
            getCountryListAPI().then((res) => {
                if (res?.status === 200) {
                    setAllCountryList(res.data)
                }
            })
        } catch (error) {

        }
    }, [])

    const handleDeleteProvider = () => {
        try {
            deleteProviders(deleteConfirmData.providerId).then((res) => {

                if (res?.status === 200) {
                    serGetProviderRun(true)
                    setDeleteConfirmData('')
                }
            })
        } catch (error) {

        }
    }
    const deleteTradeData = () => {

        try {
            deleteTrade(deleteConfirmData).then((res) => {

                if (res?.status === 200) {
                    setTradeListRun(true)
                    handleGetTradeList(countryShow)
                }
            })
        } catch (error) {

        }
    }

    const deletefeedbackData = () => {
        try {
            DeleteFeedBack(deleteConfirmData.feedbackId).then((res) => {

                if (res?.status === 200) {
                    serGetfeedbackRun(true)
                    setDeleteConfirmData('')
                }
            })
        } catch (error) {

        }

    }

    const deleteTradeSystem = () => {

        try {
            deleteStrategy(deleteConfirmData).then((res) => {

                if (res?.status === 200) {
                    setRunAgainStrategyAPI(true)
                    setDeleteConfirmData('')
                }
            })
        } catch (error) {

        }
    }
    const deleteConfirm = () => {
        if (deleteConfirmData !== '' && deleteConfirmData !== undefined) {
            if (deleteType == 'trade') {
                deleteTradeData()
            } else if (deleteType == 'provider') {
                handleDeleteProvider()
            }
            else if (deleteType == 'strategy') {
                deleteTradeSystem()
            }
            else if (deleteType == 'feedback') {
                deletefeedbackData()
            } else if (deleteType == 'commission') {
                setDeleteConfirmCommi(true)
            }else if(deleteType == 'helpVideo'){
                deleteHelpVideo()
            }
        }
        setDeleteType('')
    }

    const HandleEdit = (value) => {

        setInputData({ providerId: value.providerId, providerName: value.providerName, parentCompany: value.parentCompany, yearAsCFDProvider: value.yearAsCFDProvider, platform: value.platform })
        setAddUpdateSelect('Update')
    }
    const handleAddProvider = () => {
        setInputData({ providerName: '', parentCompany: '', yearAsCFDProvider: '', platform: '' })
        setAddUpdateSelect('Add')
        setEditTrue(false)
        setActionEdit(false)
    }
    const HandleAddCummission = (value) => {
        setInputCummission({ ...inputCummission, providerId: value.providerId })

    }
    const HandleViewCummission = (providerId) => {
        try {
            GetProviderCummission(providerId).then((res) => {
                if (res?.status === 200) {
                    setProviderCummission(res.data)
                }
            })
        } catch (error) {

        }
    }


    useEffect(() => {
        let data = {
            id: userType?.role == 'user' ? userType?.userId : '0',
            pageNumber: 1,
            pageSize: 15
        }
        try {
            getTradeSystemBYID(data).then((res) => {
                if (res?.status === 200) {
                    setStrategyList(res.data.data)
                    setRunAgainStrategyAPI(false)
                }
            })
        } catch (error) {

        }
    }, [runAgainStrategyAPI == true && runAgainStrategyAPI])

    const handleEditStrategy = (value) => {
        setEditTrue(true)
        setSerategyInput({ ...strategyInput, UserId: userType?.userId, id: value.tradingSystemId, Name: value.name, Notes: value.notes, EntryStrategy: value.entryStrategy, ExitStrategy: value.exitStrategy, ScaleInOutStrategy: value.scaleInOutStrategy })
    }
    // strategy End

    //  Trade List start
    const handleGetTradeList = (Country) => {
        let data = {
            id: 0,
            pageNumber: currentPage,
            pageSize: pageSize,
            search: search,
            Country: Country
        }
        try {
            gettradeList(data).then((res) => {

                if (res?.status === 200) {
                    setGetTraderun(false)
                    setTradeListRun(false)
                    setTradeList(res.data.data)
                    setPagination({ ...pagination, totalPages: res.data.totalPages })
                    if (res.data.data.length > 0) {

                        setCountryShow(res.data.data[0]?.country)
                    }
                }
            })
        } catch (error) {

        }
        // }
    }

    // feedback
    const feedbackhandler = () => {
        let data = {
            id: userType?.userId,
            pageNumber: 1,
            pageSize: 15,
            search: search,

        }
        try {
            getFeedBack(data).then((res) => {

                if (res?.status === 200) {

                    setgetfeedback(res.data.data)
                    setfeedbackPagination({ ...feedbackpagination, feedbacktotalPages: res.data.totalPages })
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {

        feedbackhandler()
        serGetfeedbackRun(false)
    }, [getfeedbackRun === true])

    useEffect(() => {
        if (countryShow && search.length == 0) {

            handleGetTradeList(countryShow)
        }

    }, [currentPage, pageNumber, pageSize, search])
    const handleSearchInput = (e) => {
        setSearch(e.target.value)
    }
    const getTradeCountryListApi = () => {
        if (Number(userType?.userId) > 0) {

            let data = {
                id: userType?.userId,
            }
            try {
                gettradeCountryList(data).then((res) => {

                    if (res?.status === 200) {
                        setCountryList(res.data)
                        if (res.data.length > 0) {
                            handleGetTradeList(res.data[0])
                            setCountryShow(res.data[0])
                        }

                    }
                })
            } catch (error) {

            }
        }
    }
    useEffect(() => {
        getTradeCountryListApi()
        // setCountryList(res.data)
    }, [userType?.userId, tradeListRun])

    const handleDeleteTrade = (val) => {

        setDeleteConfirmData(val.tradeId)
        setDeleteType('trade')
        setDeleteMsg('delete this Trade')
    }

    const handleDeletefeedback = (val) => {

        setDeleteConfirmData(val)
        setDeleteType('feedback')
        setDeleteMsg('delete this feedback')

    }

    const handleDeleteP = (val) => {
        setDeleteConfirmData(val)
        setDeleteType('provider')
        setDeleteMsg('delete this provider')
    }
    //  Trade List End

    // pagination
    const handlePageChange = (newPage) => {
        setGetTraderun(true)
        setPagination({ ...pagination, currentPage: currentPage + newPage })
        // Perform data fetching or other actions when page changes
    };
    //

    const feedbackhandlePageChange = (newPage) => {
        // setGetTraderun(true)
        setfeedbackPagination({ ...pagination, currentPage: currentPage + newPage })
        // Perform data fetching or other actions when page changes
    };


    const handleDeleteTredingSystem = (val) => {
        setDeleteMsg('delete this Trading System')
        setDeleteConfirmData(val)
        setDeleteType('strategy')

    }


    const handleAction = (val) => {
        // console.log(val)
        if (!actionEdit) {

            setEditError({ entryErr: false, tragerErr: false, stoplossErr: false })
            setEditTradeLineData(val)
            setActionEdit(true)
        }
    }
    const handleChangeStockInput = (e) => {

        if (e.target.name == 'stopLoss') {
            if ((Number(e.target.value) >= 0 || e.target.value == '.') && e.target.value != '') {
                setEditError({ ...editerror, stoplossErr: false })
                let n = e.target.value.split('.')
                var roundedNumber = e.target.value;
                var decimalIndex = roundedNumber.indexOf(".");
                if (decimalIndex !== -1) {
                    var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                    if (decimalPlaces > 2) {
                        roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                    }
                }
                // if (editTradeLineData?.direction == true) {
                //     if (Number(e.target.value) < Number(editTradeLineData?.entry)) {
                //         if (n.length == 2) {
                //             if (n[0].length <= 10) {
                //                 if (e.target.value.length == 1) {
                //                     setEditError({ ...editerror, stoplossErr: true })
                //                 } else {
                //                     setEditError({ ...editerror, stoplossErr: false })
                //                 }
                //                 setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber, closed: roundedNumber })
                //             }

                //         } else if (n.length == 1 && e.target.value.length <= 10) {
                //             setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber, closed: roundedNumber })
                //             if (e.target.value.length == 0) {
                //                 setEditError({ ...editerror, stoplossErr: true })
                //             } else {
                //                 setEditError({ ...editerror, stoplossErr: false })
                //             }
                //         }
                //     } else {
                //         toast.error('Set lower stoploss price than entry while long trading')
                //     }
                // } else {
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        if (e.target.value.length == 1) {
                            setEditError({ ...editerror, stoplossErr: true })
                        } else {
                            setEditError({ ...editerror, stoplossErr: false })
                        }
                        setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber, closed: roundedNumber })
                    }
                } else if (n.length == 1 && e.target.value.length <= 10) {
                    if (e.target.value.length == 0) {
                        setEditError({ ...editerror, stoplossErr: true })
                    } else {
                        setEditError({ ...editerror, stoplossErr: false })
                    }
                    setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber, closed: roundedNumber })
                }

                // }
            } else if (e.target.value == '') {
                setEditError({ ...editerror, stoplossErr: true })
                setEditTradeLineData({ ...editTradeLineData, [e.target.name]: '', closed: '' })
            }

        } else if (e.target.name == 'tradingSystemId' || e.target.name == 'action') {
            setEditTradeLineData({ ...editTradeLineData, [e.target.name]: e.target.value })
        } else if (e.target.name == 'target') {
            if ((Number(e.target.value) >= 0 || e.target.value == '.') && e.target.value != '') {
                setEditError({ ...editerror, targetErr: false })
                let n = e.target.value.split('.')
                var roundedNumber = e.target.value;
                var decimalIndex = roundedNumber.indexOf(".");
                if (decimalIndex !== -1) {
                    var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                    if (decimalPlaces > 2) {
                        roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                    }
                }
                if (editTradeLineData?.direction == false) {
                    if (Number(e.target.value) < Number(editTradeLineData?.entry)) {
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber })
                            }
                        } else if (n.length == 1 && e.target.value.length <= 10) {
                            setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber })
                        }
                    } else {
                        toast.error('Set lower target price than entry while short trading')
                    }
                } else {
                    if (n.length == 2) {
                        if (n[0].length <= 10) {
                            if (e.target.value.length <= 1) {
                                setEditError({ ...editerror, tragerErr: true })
                            } else {
                                setEditError({ ...editerror, tragerErr: false })
                            }
                            setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber })
                        }
                    } else if (n.length == 1 && e.target.value.length <= 10) {
                        if (e.target.value.length == 0) {
                            setEditError({ ...editerror, tragerErr: true })
                        } else {
                            setEditError({ ...editerror, tragerErr: false })
                        }
                        setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber })
                    }
                }
            } else {
                setEditTradeLineData({ ...editTradeLineData, [e.target.name]: '' })
                setEditError({ ...editerror, targetErr: true })
            }
        }
        else if (e.target.name == 'entry') {
            if ((Number(e.target.value) >= 0 || e.target.value == '.') && e.target.value != '') {
                setEditError({ ...editerror, entryErr: false })
                let n = e.target.value.split('.')
                var roundedNumber = e.target.value;
                var decimalIndex = roundedNumber.indexOf(".");
                if (decimalIndex !== -1) {
                    var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                    if (decimalPlaces > 2) {
                        roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                    }
                }
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber, })
                    }
                } else if (n.length == 1 && e.target.value.length <= 10) {
                    setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber, })
                }
            } else {
                setEditTradeLineData({ ...editTradeLineData, [e.target.name]: '' })
                setEditError({ ...editerror, entryErr: true })

            }
        }
    }

    const handleUpdateAction = () => {
        const formData = new FormData();
        formData.append('Action', editTradeLineData.action);
        formData.append('Brokrage', editTradeLineData.brokrage);
        formData.append('Changes', editTradeLineData.changes);
        formData.append('UserId', editTradeLineData.userId);
        formData.append('Country', editTradeLineData.country.toUpperCase());
        formData.append('Direction', editTradeLineData.direction);
        formData.append('Entry', editTradeLineData.entry);
        formData.append('Margin', editTradeLineData.margin);
        formData.append('PositionStatus', editTradeLineData.positionStatus);
        formData.append('Price', editTradeLineData.price);
        formData.append('ProviderName', editTradeLineData.providerName);
        formData.append('StockCode', editTradeLineData.stockCode);
        formData.append('StockId', editTradeLineData.stockId);
        formData.append('StockName', editTradeLineData.stockName);
        formData.append('StopLoss', editTradeLineData.stopLoss);
        formData.append('Strategy', editTradeLineData.strategy);
        formData.append('Target', editTradeLineData.target);
        formData.append('TradeDate', editTradeLineData.tradeDate);
        formData.append('TradeType', editTradeLineData.tradeType);
        formData.append('TradingSystemId', editTradeLineData.tradingSystemId);
        formData.append('Closed', editTradeLineData.closed);
        // formData.append('file', editTradeLineData.imgFile);
        if (!entryErr && !stoplossErr && !tragerErr) {

            try {
                editTrade(formData, editTradeLineData.tradeId).then((res) => {
                    if (res.status == 200) {
                        setEditTradeLineData({})
                        setActionEdit(false)
                        handleGetTradeList(countryShow)
                    }
                })
            } catch (error) {

            }
        }

    }
    const handleBlurMsg = (e) => {
        if (e.target.name == 'stopLoss') {
            // if (editTradeLineData?.direction == false) {
            //     if (Number(e.target.value) <= Number(editTradeLineData?.entry)) {
            //         setEditError({ ...editerror, stoplossErr: true })
            //         toast.error('Set higher stop loss price than entry while short trading')
            //     }
            // }
        } else if (e.target.name == 'target') {
            if (editTradeLineData?.direction == true) {
                if (Number(e.target.value) <= Number(editTradeLineData?.entry)) {
                    setEditError({ ...editerror, tragerErr: true })
                    toast.error('Set higher target price than entry while long trading')
                }
            }
        }

        // if (Number(e.target.value) >= 0) {
        //     if (entry !== '') {
        //         if (e.target.name == 'target') {
        //             if (longShort === true) {
        //                 if (Number(entry) > Number(e.target.value)) {
        //                     toast.error('Set higher target price than entry while long trading')
        //                     setError({ ...error, target: false })
        //                 } else {
        //                     setError({ ...error, target: true })
        //                 }
        //             } else {
        //                 setError({ ...error, target: true })
        //             }
        //         } else {
        //             if (longShort === false) {
        //                 if (Number(entry) >= Number(e.target.value)) {
        //                     toast.error('Set higher stop loss price than entry while short trading')
        //                     setError({ ...error, stopLoss: false })
        //                 } else {
        //                     setError({ ...error, stopLoss: true })
        //                 }
        //             } else {
        //                 setError({ ...error, stopLoss: true })
        //             }
        //         }
        //     }
        // }
    }
    const handlePendingTradeView = (val) => {
        setTradeCode(val.stockCode)
        setTradeDetails(val)
    }
    const handleChangeTradeType = (val) => {
        setEditTradeLineData({ ...editTradeLineData, direction: val, stopLoss: '', target: '' })
        setEditError({ ...editerror, tragerErr: true, stoplossErr: true })
    }
    const handleChangeTabChange = (val) => {
        setShowSearchInput(val)
        setActionEdit(false)
        setEditError({ entryErr: false, tragerErr: false, stoplossErr: false })
        // actionEdit
    }
    const handleChangeCountry = (value) => {
        handleGetTradeList(value)
        setGetTraderun(true)
        setPagination({ ...pagination, currentPage: 1, pageNumber: 1 })
        setCountryShow(value.toUpperCase())
        setActionEdit(false)
        setEditError({ entryErr: false, tragerErr: false, stoplossErr: false })
    }
    const helpVideohandlePageChange = (newPage) => {
        setGetTraderun(true)
        setHelpVideoPagination({ ...helpVideopagination, helpVideocurrentPage: helpVideocurrentPage + newPage })
        // Perform data fetching or other actions when page changes
    };

    const getHelpVideo=()=>{
        let pageData = {
            pageNumber: helpVideocurrentPage,
            pageSize: helpVideopageSize
        }

        try {
            GetHelpVideos(pageData).then((res) => {
                if (res?.status === 200) {
                    setHelpVideoList(res.data.data)

                    setHelpVideoPagination({ ...helpVideopagination, helpVideototalPages: res.data.totalPages })
                    // console.log(res.data.data)
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getHelpVideo()
    }, [helpVideocurrentPage])
    const handleEditHelpVideo = (value) => {
        // console.log(value , "value")
        setAddUpdateVideoSelect('edit')
        // setInputVideo({...value,helpNeme:`https://api.trading.tradinglounge.com/${value.helpNeme}`,reRenderV:1 })
        setInputVideo({...value,helpNeme:`${value.helpNeme}`,reRenderV:1 })
        // const [inputVideo, setInputVideo] = useState({UserId:'', ModuleNeme: '', HelpNeme: '', Description: '', videoFile: '',reRenderV:1 })
    }
    const deleteHelpVideo = () => {
        try {
            deleteHelpVideoAPI(deleteConfirmData.tradingHelpId).then((res) => {
                if (res?.status === 200) {
                    getHelpVideo()
                }
            })
        } catch (error) {

        }
    }
    const handleDeleteHelpVideo = (val) => {

        setDeleteConfirmData(val)
        setDeleteType('helpVideo')
        setDeleteMsg('delete this Video')

    }
    return (
        <>
            <div class="row">
                <section>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="postion-bx-min mt-4">

                                    <div class="postion-frm mt-2 table-input1" style={{ "border-top": "1px solid #cbd0dd" }}>
                                        {/* <span class="padding-ds">Pending Positions</span>
                                        <span class="padding-ds">Open Positions</span>
                                        <span class="padding-ds">Watchlist</span> */}
                                        <div class="col-sm-12 tabs-sec">
                                            <ul class="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                                                <li><button class="nav-link active color-thumb" id="pills-profile-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                                    aria-selected="false" onClick={() => handleChangeTabChange(false)}><span >Providers</span></button></li>
                                                <li ><button class="nav-link  color-thumb" id="pills-profile-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-strategy" type="button" role="tab" aria-controls="pills-home"
                                                    aria-selected="false" onClick={() => handleChangeTabChange(false)}><span >Trading System</span></button></li>

                                                <li><button class="nav-link color-thumb" id="pills-Trade-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-Trade" type="button" role="tab" aria-controls="pills-Trade"
                                                    aria-selected="false" onClick={() => handleChangeTabChange(true)}><span >Trades</span></button></li>

                                                <li><button class="nav-link color-thumb" id="pills-feedback-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-feedback" type="button" role="tab" aria-controls="pills-feedback"
                                                    aria-selected="false" onClick={() => handleChangeTabChange(false)}><span >Feedback</span></button></li>
                                                <li><button class="nav-link color-thumb" id="pills-help-video-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-help-video" type="button" role="tab" aria-controls="pills-feedback"
                                                    aria-selected="false" onClick={() => handleChangeTabChange(false)}><span >Help Videos</span></button></li>
                                                {
                                                    showSearchInput &&
                                                    <li><button class="nav-link search-input"><input name='search' value={search} className='search-input-main' onKeyUp={(e) => e.key == 'Enter' && handleGetTradeList(countryShow)} onChange={handleSearchInput} placeholder='search by code...' />
                                                        <span ><BiSearchAlt onClick={() => handleGetTradeList(countryShow)} /></span>
                                                    </button></li>
                                                }
                                            </ul>
                                            <div class="tab-content" id="pills-tabContent">
                                                <div class="tab-pane fade show active" id="pills-profile" role="tabpanel"
                                                    aria-labelledby="pills-profile-tab">
                                                    <div className='d-md-flex d-sm-block'>
                                                        <button class="btn  ms-auto d-block " data-bs-toggle="modal" data-bs-target="#providerList">Providers Stock List</button>
                                                        <button class="btn  ms-md-1 ms-sm-auto d-block " onClick={handleAddProvider} data-bs-toggle="modal" data-bs-target="#addProvider">Add Provider <span> <i class="bi bi-plus-lg"></i> </span></button>
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered mt-3 text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="adm-pr1 color-grey"><b>Provider</b></th>
                                                                    <th scope="col" class="adm-pr2 color-grey"><b>Parent Company</b></th>
                                                                    <th scope="col" class="adm-pr3 color-grey"><b>Years As CFD Provider</b></th>
                                                                    <th scope="col" class="adm-pr4 color-grey"><b>Platforms</b></th>
                                                                    <th scope="col" class="adm-pr4 color-grey"><b>Commission Details</b></th>
                                                                    <th scope="col" class="adm-pr4 color-grey"><b>Commission</b></th>
                                                                    <th class="border-end-0 color-grey">Edit</th>
                                                                    <th className='color-grey'>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    providerDataList.map((val, ind) => {
                                                                        return (
                                                                            <tr key={ind}>

                                                                                <th scope="row" class="border-end-0">{val.providerName}</th>
                                                                                <td>{val.parentCompany}</td>
                                                                                <td>{val.yearAsCFDProvider}</td>
                                                                                <td>{val.platform}</td>
                                                                                <td><button class="btn padding-btn-dtd edit-btn-adm" title="Add" data-bs-toggle="modal" data-bs-target="#cummissionDetails" onClick={() => HandleViewCummission(val?.providerId)}> <img src="assets/image/folder.png" class="img-fluid" alt='' />View</button></td>
                                                                                <td><button class="btn padding-btn-dtd edit-btn-adm" title="Add" data-bs-toggle="modal" data-bs-target="#addCummission" onClick={() => HandleAddCummission(val)}> <img src="assets/image/edit.png" class="img-fluid" /> Add</button></td>
                                                                                <td><button class="btn padding-btn-dtd edit-btn-adm" title="Edit" data-bs-toggle="modal" data-bs-target="#addProvider" onClick={() => HandleEdit(val)}> <img src="assets/image/edit.png" class="img-fluid" /> Edit</button></td>
                                                                                <td><button class="btn padding-btn-dtd delete-admin" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleDeleteP(val)}> <i class="bi bi-trash3-fill"></i> </button></td>


                                                                            </tr>
                                                                        )
                                                                    })
                                                                }


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-strategy" role="tabpanel"
                                                    aria-labelledby="pills-profile-tab">
                                                    <div className='d-flex'>
                                                        <button class="btn  ms-auto d-block " onClick={handleAddProvider} data-bs-toggle="modal" data-bs-target="#addEditStrategy">Add Strategy <span> <i class="bi bi-plus-lg"></i> </span></button>
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered mt-3 text-center">
                                                            <thead>
                                                                <tr class="border-bottom-0">
                                                                    <th scope="row" class="ad-tab-b color-grey ">Name</th>
                                                                    <th scope="row" class="ad-tab-b color-grey ">Entry Strategy </th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Exit Strategy</th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Scale In/Out Strategy</th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Comments</th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Edit</th>
                                                                    <th className='color-grey'>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    strategyList?.map((val, ind) => {
                                                                        return (
                                                                            <tr key={ind}>
                                                                                <th> &nbsp;{val.name} </th>
                                                                                <td> &nbsp;{val.entryStrategy} </td>
                                                                                <td> &nbsp;{val.exitStrategy} </td>
                                                                                <td> &nbsp;{val.scaleInOutStrategy} </td>
                                                                                <td> &nbsp;{val.notes} </td>
                                                                                <td onClick={() => handleEditStrategy(val)}>
                                                                                    <button class="btn padding-btn-dtd edit-btn-adm" data-bs-toggle="modal" data-bs-target="#addEditStrategy" title="Edit">
                                                                                        <img src="assets/image/edit1.png" class="img-fluid" />  </button>
                                                                                </td>
                                                                                <td><button class="btn padding-btn-dtd delete-admin" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleDeleteTredingSystem(val)}> <i class="bi bi-trash3-fill"></i> </button></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade " id="pills-Trade" role="tabpanel"
                                                    aria-labelledby="pills-Trade-tab">
                                                    <div className='d-flex admin-country'>
                                                        {
                                                            countryList.length > 0 &&
                                                            <div className='country-width'>
                                                                {
                                                                    countryList?.map((value, index) => {
                                                                        return (
                                                                            <span key={index} class={countryShow?.toUpperCase() === value?.toUpperCase() ? "frm-hd-t me-1 pe-auto admin-country-w-h" : "frm-hd-t me-1 country-Active pe-auto admin-country-w-h"} onClick={() => handleChangeCountry(value)}>{value}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }

                                                        <button class="btn  ms-auto d-block" onClick={handleAddProvider} data-bs-toggle="modal" data-bs-target="#createTrade">Create Trade <span> <i class="bi bi-plus-lg"></i> </span></button>
                                                    </div>
                                                    <div class="table-responsive text-center">
                                                        <table class="table table-bordered mt-3 text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='color-grey'>Date</th>
                                                                    <th scope="col" className='color-grey'>Code</th>
                                                                    {/* <th scope="col" className='color-grey'>Strategy</th> */}
                                                                    <th scope="col" className='color-grey'>Direction</th>
                                                                    <th scope="col" className='color-grey'>Entry</th>
                                                                    <th scope="col" className='color-grey'>Stop</th>
                                                                    <th scope="col" className='color-grey'>Target </th>
                                                                    <th scope="col" className='color-grey'>strategy</th>
                                                                    <th scope="col" className='color-grey'>Actions</th>
                                                                    {/* <th scope="col" className='color-grey'>Actions</th> */}
                                                                    {/* <th scope="col" className='color-grey'>Closed</th> */}
                                                                    {/* <th scope="col" className='color-grey'>Trade This</th> */}
                                                                    <th scope="col" className='color-grey'>Edit</th>
                                                                    <th scope="col" className='color-grey'>Image</th>
                                                                    <th scope="col" className='color-grey'>Delete</th>
                                                                    <th scope="col" className='color-grey'>View</th>

                                                                    {/* <th scope="col" className='color-grey'>Watchlist</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    tradeList.map((val, ind) => {
                                                                        let symboleData
                                                                        let filteredCountry = allCountryList.filter((value) => value.key.toUpperCase() == val.country.toUpperCase())
                                                                        if (filteredCountry.length > 0) {

                                                                            symboleData = CountryCurrencyList.filter((value) => value.code == filteredCountry[0].value)
                                                                        }
                                                                        return (
                                                                            countryShow.toUpperCase() === val.country.toUpperCase() &&


                                                                            <tr key={ind}>
                                                                                <th scope="row">{moment(val.tradeDate).format('DD MMM YYYY')}</th>
                                                                                <td>{val.stockCode}</td>
                                                                                {/* <td>test</td> */}

                                                                                {
                                                                                    (actionEdit && editTradeLineData.tradeId == val.tradeId) ?
                                                                                        <>
                                                                                            <td class="text-center">
                                                                                                <nav className="menu">
                                                                                                    <Link to="#" className={editTradeLineData.direction == true ? "menu-link menu-active py-0px" : "menu-link py-0px"} onClick={() => handleChangeTradeType(true)} data-menu="0">Long</Link>
                                                                                                    <Link to="#" className={editTradeLineData.direction == false ? "menu-link menu-active py-0px" : "menu-link py-0px"} onClick={() => handleChangeTradeType(false)} data-menu="3">Short</Link>
                                                                                                </nav>
                                                                                            </td>
                                                                                            <td class="text-center"><input className={actionEdit == true && entryErr == true ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='entry' value={editTradeLineData?.entry} onChange={handleChangeStockInput} /></td>
                                                                                            <td class="text-center"><input className={actionEdit == true && stoplossErr == true ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='stopLoss' onBlur={handleBlurMsg} value={editTradeLineData?.stopLoss} onChange={handleChangeStockInput} /></td>
                                                                                            <td class="text-center"><input className={actionEdit == true && tragerErr == true ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='target' onBlur={handleBlurMsg} value={editTradeLineData?.target} onChange={handleChangeStockInput} /></td>
                                                                                            <td class="text-center">
                                                                                                <select className={actionEdit == true && editTradeLineData?.tradingSystemId == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='tradingSystemId' value={editTradeLineData?.tradingSystemId} onChange={handleChangeStockInput} >
                                                                                                    {
                                                                                                        strategyList.map((value, ind) => {
                                                                                                            return (
                                                                                                                <option key={ind} value={value.tradingSystemId}>{value.name}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </select>
                                                                                                {/* <input className={actionEdit == true && editTradeLineData?.tradingSystemId == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='target' value={editTradeLineData.tradingSystemId} onChange={handleChangeStockInput} /> */}
                                                                                            </td>
                                                                                            <td class="text-center"><input className={actionEdit == true && editTradeLineData?.action == '' ? "know-commper-bx-min w-100" : "know-commper-bx-min w-100"} name='action' value={editTradeLineData?.action} onChange={handleChangeStockInput} /></td>
                                                                                            <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" onClick={() => handleUpdateAction(val)}> Save </button> </td>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {/* tradingSystemId */}
                                                                                            <td>{val?.direction === true ? 'Long' : 'Short'}</td>
                                                                                            <td>{symboleData[0]?.symbol_native} {Number(val?.entry || 0)}</td>
                                                                                            <td>{symboleData[0]?.symbol_native} {Number(val?.stopLoss || 0)}</td>
                                                                                            <td>{symboleData[0]?.symbol_native} {Number(val?.target || 0)}</td>
                                                                                            <td>{val.tradingSystems.name}</td>
                                                                                            <td>{val?.action}</td>
                                                                                            <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" onClick={() => !actionEdit && handleAction(val)}> <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td>
                                                                                        </>
                                                                                }

                                                                                {/* <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" title='Edit' data-bs-toggle="modal" data-bs-target="#EditTrade" onClick={() => setTradeData(val)}> <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td> */}
                                                                                <td><button class="btn padding-btn-dtd open-btn w-100" data-bs-toggle="modal" title='View Image' data-bs-target="#viewImage" onClick={() => setChartImg(val.chartFile)}> <img src="assets/image/folder.png" class="img-fluid" alt='' />  </button> </td>
                                                                                <td><button class="btn padding-btn-dtd delete-btn w-100 delete-admin" title='Delete' data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleDeleteTrade(val)}>  <i class="bi bi-trash3-fill"></i>  </button> </td>
                                                                                <td><button class="btn padding-btn-dtd open-btn w-100" data-bs-toggle="modal" title='View Chart' data-bs-target="#StockView" onClick={() => handlePendingTradeView(val)}> <img src="assets/image/folder.png" class="img-fluid" alt='' />  </button> </td>



                                                                            </tr>
                                                                        )

                                                                    })
                                                                }


                                                            </tbody>
                                                        </table>
                                                        <Pagination
                                                            currentPage={currentPage}
                                                            totalPages={totalPages}
                                                            onPageChange={handlePageChange}
                                                            setPagination={setPagination}
                                                            pageNumber={pageNumber}
                                                            pageSize={pageSize}
                                                            pagination={pagination}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade " id="pills-feedback" role="tabpanel"
                                                    aria-labelledby="pills-feedback-tab">
                                                    {/* <div className='d-flex admin-country'>
                                                        {
                                                            countryList.length > 0 &&
                                                            <div className='country-width'>
                                                                {
                                                                    countryList?.map((value, index) => {
                                                                        return (
                                                                            <span key={index} class={countryShow?.toUpperCase() === value?.toUpperCase() ? "frm-hd-t me-1 pe-auto admin-country-w-h" : "frm-hd-t me-1 country-Active pe-auto admin-country-w-h"} onClick={() =>handleChangeCountry(value)}>{value}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div> */}
                                                    <div class="table-responsive text-center">
                                                        <table class="table table-bordered mt-3 text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='color-grey'>UserId</th>
                                                                    <th scope="col" className='color-grey'>Email</th>
                                                                    <th scope="col" className='color-grey'>Subject</th>
                                                                    <th scope="col" className='color-grey'>Message</th>
                                                                    <th scope="col" className='color-grey'>Delete</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    getfeedback.map((val, ind) => {
                                                                        return (


                                                                            <tr key={ind}>
                                                                                <th scope="row">{val.userId}</th>
                                                                                <td>{val.email}</td>
                                                                                <td>{val.subject}</td>

                                                                                <td>{val.description}</td>

                                                                                <td><button class="btn padding-btn-dtd delete-btn w-100 delete-admin" title='Delete' data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleDeletefeedback(val)}>  <i class="bi bi-trash3-fill"></i>  </button> </td>
                                                                            </tr>
                                                                        )

                                                                    })
                                                                }


                                                            </tbody>
                                                        </table>
                                                        <Pagination
                                                            currentPage={feedbackcurrentPage}
                                                            totalPages={feedbacktotalPages}
                                                            onPageChange={feedbackhandlePageChange}
                                                            setPagination={setfeedbackPagination}
                                                            pageNumber={feedbackpageNumber}
                                                            pageSize={feedbackpageSize}
                                                            pagination={feedbackpagination}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade " id="pills-help-video" role="tabpanel"
                                                    aria-labelledby="pills-help-video-tab">
                                                    <div className='d-flex admin-country'>
                                                        <button class="btn  ms-auto d-block" onClick={()=>setAddUpdateVideoSelect('add')} data-bs-toggle="modal" data-bs-target="#addHelpVideo">Upload Video <span> <i class="bi bi-plus-lg"></i> </span></button>
                                                    </div>
                                                    <div class="table-responsive text-center">
                                                        <table class="table table-bordered mt-3 text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='color-grey'>Name</th>
                                                                    <th scope="col" className='color-grey'>Description</th>
                                                                    {/* <th scope="col" className='color-grey'>video</th> */}
                                                                    <th scope="col" className='color-grey'>Edit</th>
                                                                    <th scope="col" className='color-grey'>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    helpVideoList.map((val, ind) => {
                                                                        {/* console.log(val , "val") */}
                                                                        return (


                                                                            <tr key={ind}>
                                                                                <td>{val.moduleNeme}</td>
                                                                                <td>{val.description}</td>
                                                                                {/* <td>{val.helpNeme}</td> */}
                                                                                <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" onClick={() => handleEditHelpVideo(val)} data-bs-toggle="modal" data-bs-target="#addHelpVideo" > <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td>
                                                                                <td><button class="btn padding-btn-dtd delete-btn w-100 delete-admin" onClick={()=>handleDeleteHelpVideo(val)} title='Delete' data-bs-toggle="modal" data-bs-target="#deleteModel">  <i class="bi bi-trash3-fill"></i>  </button> </td>
                                                                            </tr>
                                                                        )

                                                                    })
                                                                }


                                                            </tbody>
                                                        </table>
                                                        <Pagination
                                                            currentPage={helpVideocurrentPage}
                                                            totalPages={helpVideototalPages}
                                                            onPageChange={helpVideohandlePageChange}
                                                            setPagination={setHelpVideoPagination}
                                                            pageNumber={helpVideopageNumber}
                                                            pageSize={helpVideopageSize}
                                                            pagination={helpVideopagination}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* setDeleteConfirmData(val.tradeId)
        setDeleteType('trade')
        setDeleteMsg('delete this Trade') */}
            <ViewImage chartImg={chartImg} />
            <CummissionDetails setDeleteConfirmCommi={setDeleteConfirmCommi} deleteConfirmCommi={deleteConfirmCommi} deleteType={deleteType} cummissionData={providerCummission} setDeleteMsg={setDeleteMsg} setDeleteType={setDeleteType} setDeleteConfirmData={setDeleteConfirmData} HandleViewCummission={HandleViewCummission} />
            <TradeModel tradeCode={tradeCode} tradeDetails={tradeDetails} countryList={allCountryList} />
            <DeleteConfirmation type={deleteMsg} deleteConfirm={deleteConfirm} />
            <ProviderModel providerDataList={providerDataList} />
            <AddCummission inputCummission={inputCummission} setInputCummission={setInputCummission} />
            <AddUpdateModel inputData={inputData} setInputData={setInputData} addUpdateSelect={addUpdateSelect} serGetProviderRun={serGetProviderRun} />
            <AddEditStrategy userType={userType} setRunAgainStrategyAPI={setRunAgainStrategyAPI} setSerategyInput={setSerategyInput} strategyInput={strategyInput} editTrue={editTrue} />
            <CreateTrade allCountryList={allCountryList} userType={userType} setGetTraderun={setGetTraderun} setTradeListRun={setTradeListRun} strategyList={strategyList} providerList={providerDataList} />
            <AddEditVideo getHelpVideo={getHelpVideo} inputVideo={inputVideo} setInputVideo={setInputVideo} addUpdateVideoSelect={addUpdateVideoSelect} />
            {/* <EditTrade userType={userType} setGetTraderun={setGetTraderun} setTradeListRun={setTradeListRun} strategyList={strategyList} providerList={providerDataList} tradeData={tradeData} setTradeData={setTradeData} /> */}
        </>

    )
}

export default AdminTable