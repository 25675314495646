import React, { useEffect, useState } from 'react'
import {
  getStockDetails,
  getRapidStockDetails,
} from "../../../services/TradeService";
import StockHeader from './ModelSetting/StockHeader'
import StockIncomeStateMent from './ModelSetting/StockIncomeStateMent'
import StockNews from './ModelSetting/StockNews'
import StockOverview from './ModelSetting/StockOverview'
import StockBalanceSheet from './ModelSetting/StockBalanceSheet'
import StockCashFlow from './ModelSetting/StockCashFlow'

const TradeModel = ({ tradeCode, tradeDetails, countryList }) => {

  const [stockData, setStockData] = useState([])
  const [stockDate, setStockdate] = useState('1W')
  const [countryCode, setCountryCode] = useState()
  const [typeData, setTypeData] = useState('Overview')
  // useEffect(() => {

  //   let data = {
  //     tradeCode, stockDate
  //   }
  //   if (tradeCode !== undefined && tradeCode !== '' && stockData !== undefined) {
  //     try {
  //       getStockDetails(data).then((res) => {
          

  //         if (res.status == 200) {
  //            console.log("stockdetails", res)
  //           setStockData(res?.data)
  //         }
  //       })
  //     } catch (error) {

  //     }
  //   }
  // }, [stockDate, typeData, tradeCode])

  useEffect(() => {
    let data = {
      tradeCode,
      stockDate,
    };
    if (
      tradeCode !== undefined &&
      tradeCode !== "" &&
      stockData !== undefined
    ) {
      try {
        getRapidStockDetails(data).then((res) => {
          if (res.status == 200) {
            localStorage?.setItem("tradeCode", JSON.stringify(tradeCode));
             localStorage?.setItem("Data", JSON.stringify(res?.data || null));
            // console.log("rapidstockdetails", res?.data);
            setStockData(res?.data);
          }
        });
      } catch (error) {}
    }
  }, [stockDate, typeData, tradeCode]);

  useEffect(() => {
    if (tradeDetails?.country?.length > 0 && tradeDetails?.country != undefined) {
      let filteredCountry = countryList.filter((val) => val.key.toUpperCase() == tradeDetails?.country.toUpperCase())
      if (filteredCountry.length > 0) {
        setCountryCode(filteredCountry[0]?.value)
      }
    }

  }, [tradeDetails?.country])

  let aus = tradeCode.includes(".AT")
  let dataBar, data

 
  if (tradeCode.includes("ASX:")) {
    let dataArr = []
    var _ss= stockData.quotes;
    
    if(stockData != undefined && stockData.quotes != undefined){
      setStockData(stockData.quotes)
    }
    
   
  } else {
    if (stockData != null && stockData != undefined) {
      const timeSeriesData = stockData["Time Series (Daily)"];
      let dataArr = []
      // Iterate over the date keys and their corresponding data
      for (const date in timeSeriesData) {
        if (timeSeriesData.hasOwnProperty(date)) {
          const dailyData = timeSeriesData[date];
          const open = parseFloat(dailyData["1. open"]);
          const high = parseFloat(dailyData["2. high"]);
          const low = parseFloat(dailyData["3. low"]);
          const close = parseFloat(dailyData["4. close"]);
          const volume = parseInt(dailyData["5. volume"]);

          // Parse the date string into a JavaScript Date object
          const parsedDate = new Date(date);
          let n = { open: open, close: close, high: high, low: low, date: parsedDate }
          dataArr.push(n)

        }
        setStockData(dataArr)
        // console.log("stockdata", stockData)
      }

    }
    // setStockData({ open: open, close: close, high: high, low: low })
    // const timeSeriesData = stockData["Time Series (Daily)"];
    // for (const date in timeSeriesData) {
    //   if (timeSeriesData.hasOwnProperty(date)) {
    //     const dailyData = timeSeriesData[date];
    //     const open = dailyData["1. open"];
    //     const high = dailyData["2. high"];
    //     const low = dailyData["3. low"];
        // const close = dailyData["4. close"];
    //     const volume = dailyData["5. volume"];
    //     const parsedDate = new Date(date);
    //   }
    // }
    // dataBar = (
    //   stockData?.map((val, ind) => {
    //     return (
    //       {
    //         color: stockData[stockData.length - (ind + 1)].open > stockData[stockData.length - (ind)]?.close ? '#94d1bc' : '#f5a8a8',
    //         time: stockData[stockData.length - (ind + 1)]?.priceDate, value: stockData[stockData.length - (ind + 1)]?.close
    //       }
    //     )
    //   })
    // );

    // data = (
    //   stockData?.map((val, ind) => {
    //     return (
    //       { time: stockData[stockData.length - (ind + 1)]?.priceDate, value: stockData[stockData.length - (ind + 1)]?.close }
    //     )
    //   })
    // );
  }
  const handleCloseTradetabModal=()=>{
    setTypeData('Overview')
    document.getElementById('pills-overview-tab').click()
  }

  return (
    <>
      <div className="modal fade show backdrop-1 z-indez-2000" data-bs-backdrop="static" id="StockView" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-lg width_900 modal-dialog-centered">
          <div className="modal-content pt-0">
            <div className="modal-body blackout-bx pb-0">
              {/* <!-------modeal-----> */}
              <button type="button" className="btn-close" onClick={handleCloseTradetabModal} data-bs-dismiss="modal" style={{'z-index':'999'}} aria-label="Close">×</button>
              <div className="row">
                <StockHeader tradeCode={tradeCode} stockData={stockData} stockDate={stockDate} />
                <div class="bot-brd "></div>
                <div class="col-sm-12 mt-3">
                  <div class="detels-bx">
                    <ul class="nav nav-pills mb-3 nav-justified" id="pills-tab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active white_text" id="pills-overview-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-overview" type="button" role="tab" aria-controls="pills-overview"
                          aria-selected="true" onClick={() => setTypeData('Overview')}>Overview</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link white_text " id="pills-cashflow-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-cashflow" type="button" role="tab"
                          aria-controls="pills-cashflow" aria-selected="false" onClick={() => setTypeData('CashFlow')}>Cashflow</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link white_text" id="pills-news-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-news" type="button" role="tab" aria-controls="pills-news"
                          aria-selected="false" onClick={() => setTypeData('News')}>News</button>
                      </li>
                      {/* <li class="nav-item" role="presentation">
                        <button class="nav-link white_text" id="pills-balancesheet-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-balancesheet" type="button" role="tab"
                          aria-controls="pills-balancesheet" aria-selected="false" onClick={() => setTypeData('BalanceSheet')}>Balance Sheet</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link white_text" id="pills-incomeStatement-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-incomeStatement" type="button" role="tab"
                          aria-controls="pills-incomeStatement"
                          aria-selected="false" onClick={() => setTypeData('IncomeStatement')}>Income Statement</button>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content" id="pills-tabContent">
              {/* {
              stockData?.length>0?<StockOverview stockData={stockData}/>:<h5 className="modal-title h4" id="exampleModalLgLabel">No Data Found</h5>
            } */}
              <StockOverview dataBar={dataBar} tradeCode={tradeCode} stockData={stockData} data={data} />
              <StockCashFlow countryCode={countryCode} tradeCode={tradeCode} typeData={typeData} />
              <StockBalanceSheet countryCode={countryCode} tradeCode={tradeCode} typeData={typeData} />
              <StockNews tradeCode={tradeCode} typeData={typeData} />
              <StockIncomeStateMent countryCode={countryCode} tradeCode={tradeCode} typeData={typeData} />
            </div>
            {/* <!-------modeal-----> */}




          </div>
        </div>
      </div>
    </>
  )
}

export default TradeModel