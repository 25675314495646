import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getOpentradeList, gettradeList } from '../../services/TradeService'
import {
  getSearchStockDetails,
  getStocksByPortFolioId,
  getRapidSearchStockDetails,
} from "../../services/HomeService";
import { GetCurrentBalabce, getProviders } from '../../services/ProviderServices'
import { getTransactionListAPI } from '../../services/ManageAccountServices'
import { currentBalanceFun } from '../commonComponent/functions/AccountDetails'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment/moment'
import { getExchangeRateAPI, getRapidExchangeRateAPI } from '../../services/commonServices'

const Hero = ({ countryList, setPlayVideo, setLoadingState, loadingState, runAgainDashBoard, helpSetting, handleClickStart, runbalanceTranAPI, setRunBalancsTranAPI, portfololioList, setSelectedUserData, setOpenTradeList, userType, setOpenTradeRun }) => {
    const [showData, setShowData] = useState({ balanceinShares: 0, balanceinCFD: 0, leverage: 0, })
    const { balanceinShares, balanceinCFD, leverage } = showData
    const [balanceDetails, setBalanceDetails] = useState('')
    const [portFolioId, setPortFolioId] = useState()
    const [providerId, setProviderId] = useState()
    const [profit, setProfit] = useState(0)
    const [helpModeActivated, setHelpModeActivated] = useState(false)
    const [financeYearType, setFinanceYearType] = useState();
    const [selectedDate, setSelectedDate] = useState();
    const [selectedSecondDate, setSelectedSecondDate] = useState();
    const [minDate, setMinDate] = useState()
    const [maxDate, setMaxDate] = useState()
    const [currencySymbol, setCurrencySymbol] = useState({ symbol: '', code: '' })
    const navigate = useNavigate()
    let currentDate = new Date()
    let currentyear = currentDate.getFullYear()




    useEffect(() => {
        let timeFrem = JSON.parse(localStorage.getItem('timeFrem'))

        if (timeFrem?.fYear !== null && timeFrem?.fYear !== undefined) {
            setFinanceYearType(timeFrem.fYear)
            handleChangeFinanceYear(timeFrem.fYear)
        } else {
            handleChangeFinanceYear(1)
        }
    }, [])
    useEffect(() => {
        let objectTimeFrem = { fYear: financeYearType, sDate: selectedDate, ssDate: selectedSecondDate }
        let timeFrem = JSON.stringify(objectTimeFrem)
        if (objectTimeFrem.fYear !== undefined && objectTimeFrem.sDate !== undefined && objectTimeFrem.ssDate !== undefined) {
            localStorage.setItem('timeFrem', timeFrem)
        }


    }, [financeYearType, selectedDate, selectedSecondDate])
    useEffect(() => {
        if (portFolioId !== undefined && portFolioId !== 'undefined') {
            localStorage.setItem('portFolioId', portFolioId)
            localStorage.setItem('currencySymbol', JSON.stringify(currencySymbol))
        }
    }, [portFolioId])



    const filterWeekends = (date) => {
        // Get the day of the week (0-6) for the provided date
        const day = date.getDay();
        // If the day of the week is Saturday (6) or Sunday (0), disable the date
        return day !== 0 && day !== 6;
    };
    // useEffect(() => {
    //     try {
    //         gettradeList(data).then((res) => {

    //         })
    //     } catch (error) {
    //         
    //     }
    // }, [runAgainDashBoard])
    useEffect(() => {

        if (portfololioList.length > 0) {
            let bId = localStorage.getItem('portFolioId')
            let symbol = {}
            if (localStorage.getItem('currencySymbol')) {
                symbol = JSON.parse(localStorage.getItem('currencySymbol'))
            }
            let n = portfololioList.filter((val) => val.portfolioId == bId)

            if (n.length > 0) {
                setPortFolioId(bId)
                setCurrencySymbol(symbol)
                // setLoadingState({ ...loadingState, portfolioLoading: true })
            } else {
                let currency = {
                    symbol: portfololioList[0]?.currencySymbol,
                    code: portfololioList[0]?.currency
                }
                setPortFolioId(portfololioList[0]?.portfolioId)
                setCurrencySymbol(currency)
                // setLoadingState({ ...loadingState, portfolioLoading: true })
                localStorage.setItem('portFolioId', portfololioList[0]?.portfolioId)
            }
        }
    }, [portfololioList])

    useEffect(() => {
        setSelectedUserData({ providerId, portFolioId, dateTo: moment(selectedSecondDate).format('MM/DD/YYYY'), dateFrom: moment(selectedDate).format('MM/DD/YYYY') })
    }, [providerId, portFolioId, selectedDate, selectedSecondDate])
    useEffect(() => {

        let data = {
            portfolioId: portFolioId,
            id: userType?.userId,
            pageNumber: 1,
            pageSize: 1000
        }

        if (data.id !== undefined && data.portfolioId !== undefined && countryList.length > 0) {
            try {
                getOpentradeList(data).then((res) => {
                    if (res?.status === 200 && res.data.data.length > 0) {
                        setOpenTradeList(res.data.data)
                        let data = res.data.data
                        let pft = 0
                        let bs = 0
                        let bcfd = 0, lev

                        for (let i = 0; i < data.length; i++) {
                            let qty = data[i].qty
                            let transp = data[i].valueInShares
                            try {
                                // getSearchStockDetails(data[i].stockCode).then((res) => {
                                getRapidSearchStockDetails(
                                  data[i].stockCode
                                ).then((res) => {
                                  if (res?.status === 200) {
                                    //  let aus = data[i].stockCode.includes('.AX')
                                    let aus =
                                      data[i].stockCode.includes("ASX:");

                                    let filteredCountry = countryList.filter(
                                      (value) =>
                                        value.key.toUpperCase() ==
                                        data[i].country.toUpperCase()
                                    );

                                    try {
                                        // getExchangeRateAPI(
                                          getRapidExchangeRateAPI(
                                        filteredCountry[0].value,
                                        data[i].portfolio.currency
                                      ).then((respons) => {
                                        if (respons?.status === 200) {
                                          let exchange = Number(
                                            respons?.data?.rates?.[
                                              data[i].portfolio.currency
                                            ] || 1
                                          );
                                          let cls;
                                          if (aus) {
                                            // const price = res.data["close"];
                                            const price =
                                              res.data.quotes[0].price;

                                            if (data[i].short == "true") {
                                              cls = price * exchange;
                                              pft = transp - cls * qty + pft;
                                            } else {
                                              cls = price * exchange;
                                              pft = pft + (cls * qty - transp);
                                            }
                                          } else {
                                            const price = res?.data?.regularMarketPrice?.raw;
                                            if (data[i].short == "true") {
                                              cls = price * exchange;
                                              pft = pft + (transp - cls * qty);
                                            } else {
                                              cls = price * exchange;
                                              pft = pft + (cls * qty - transp);
                                            }
                                          }
                                          setProfit(pft);
                                        }
                                      });
                                    } catch (error) {}
                                  }
                                });
                            } catch (error) {

                            }
                            bs = bs + data[i].valueInShares
                            bcfd = bcfd + data[i].valueInCFD
                        }
                        lev = (bcfd / bs) * 100
                        setShowData({ ...showData, profit: pft.toFixed(2), balanceinShares: bs, balanceinCFD: bcfd, leverage: lev })
                    } else {
                        setShowData({ ...showData, balanceinShares: 0, balanceinCFD: 0, leverage: 0 })
                    }
                })
            } catch (error) {

            }
            // getbalanceTrans()
        }
        setLoadingState({ ...loadingState, balanceLoading: true })
    }, [portFolioId, userType, runAgainDashBoard, countryList.length])

    const handleChangePortFolio = (e) => {

        setShowData({ ...showData, balanceinShares: 0, balanceinCFD: 0 })
        setBalanceDetails({ ...balanceDetails, currentBalance: 0 })
        setProfit(0)
        let getCurrency = portfololioList.filter((val) => val.portfolioId == Number(e.target.value))

        let currency = {
            symbol: getCurrency[0]?.currencySymbol,
            code: getCurrency[0]?.currency
        }
        localStorage.setItem('currencySymbol', JSON.stringify(currency))
        setCurrencySymbol(currency)
        setPortFolioId(Number(e.target.value))

    }

    const getbalanceTrans = () => {
        let data = {
            userId: userType?.userId,
            portfolioId: portFolioId
        }
        
        if (data.userId !== undefined && data.portfolioId >0) {
// console.log(data ,"data")
            try {
                GetCurrentBalabce(data).then((res) => {
                    if (res?.status === 200) {

                        setBalanceDetails(res.data)
                    }
                })
            } catch (error) {

            }
            // setBalanceDetails(currentBalanceFun())
        }


    }
    useEffect(() => {
        getbalanceTrans()
    }, [portFolioId, runbalanceTranAPI])
    // const handleActiveHelpMode=()=>{
    //     handleClickStart()
    //     setHelpModeActivated(true)
    // }
    useEffect(() => {
        setHelpModeActivated(helpSetting.run)
    }, [helpSetting.run])

    const handleChangeFinanceYear = (ev) => {
        // 
        setFinanceYearType(ev)

        if (ev == '0') {
            setMinDate(new Date(currentyear, 6, 1))
            setMaxDate(new Date(currentyear + 1, 5, 30))
            setSelectedDate(new Date(currentyear, 6, 1))
            setSelectedSecondDate(new Date(currentyear + 1, 5, 30))
        } else if (ev == '1') {
            setMinDate(new Date(currentyear, 0, 1))
            setMaxDate(new Date(currentyear, 11, 31))
            setSelectedDate(new Date(currentyear, 0, 1))
            setSelectedSecondDate(new Date(currentyear, 11, 31))
        }
        else if (ev == '2') {
            setMinDate(new Date(currentyear - 1, 6, 1))
            setMaxDate(new Date(currentyear, 5, 30))
            setSelectedDate(new Date(currentyear - 1, 6, 1))
            setSelectedSecondDate(new Date(currentyear, 5, 30))
        }
        else if (ev == '3') {
            setMinDate(new Date(currentyear - 1, 0, 1))
            setMaxDate(new Date(currentyear - 1, 11, 31))
            setSelectedDate(new Date(currentyear - 1, 0, 1))
            setSelectedSecondDate(new Date(currentyear - 1, 11, 31))
        }
    }
    const handleNavigatetrade = () => {
        navigate({
            pathname: '/trade',
        });
    }

    useEffect(() => {
        let data = {
            PageNumber: 1,
            PageSize: 100
        }
        try {
            getProviders(data).then((res) => {
                if (res.status == 200) {
                    let data = res.data.data
                    let s = []
                    for (let i = 0; i < portfololioList.length; i++) {
                        let n = data.filter((val) => val.providerId == portfololioList[i].providerId)
                        if (n.length > 0) {
                            portfololioList[i].status = true
                        } else {
                            portfololioList[i].status = false
                        }
                    }
                    // setBrokerList(portfololioList)
                }
            })
        } catch (error) {

        }

    }, [portfololioList])

    return (
        <>
            <div className="protfolio-sec">
                {/* <!--------left-protfolio start----> */}
                <div className="row">

                    <div className="col-lg-3 ">
                        <div className="left-protfolio">
                            {/* <!--------left-protfolio----> */}
                            <h4 className="pb-1">Time Frame</h4>
                            <div className="two-selctone">

                                <div className="row">
                                    <div className="col-lg-12 ">
                                        <select className="form-select time_port mb-2" value={financeYearType} onChange={(e) => handleChangeFinanceYear(e.target.value)} aria-label=".form-select-lg example">
                                            <option selected value={0}>This Financial Year</option>
                                            <option value={1}>This Calendar Year</option>
                                            <option value={2}>Last Financial Year</option>
                                            <option value={3}>Last Calendar Year</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <DatePicker
                                            className='date-picker ps-2'
                                            dateFormat="MM / yyyy"
                                            selected={selectedDate}
                                            onChange={(date) => setSelectedDate(date)}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                        />
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <DatePicker
                                            className='date-picker ps-2'
                                            selected={selectedSecondDate}
                                            dateFormat="MM / yyyy"
                                            onChange={(date) => setSelectedSecondDate(date)}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                        />
                                    </div>
                                </div>
                            </div>



                            <div className="Balance-bx">
                                <p className='mb-0 '>Balance in Shares</p>
                                <h4 className='herofont'>{currencySymbol.symbol} {(showData.balanceinShares)?.toFixed(2)} <span className='currency_code'>{currencySymbol?.code}</span></h4>
                            </div>

                            <div className="Balance-bx mt-3 mb-3">
                                <p className='mb-0'>Balance in Cash</p>
                                <h4 className='herofont'>{currencySymbol.symbol} {(balanceDetails?.currentBalance || 0).toFixed(2)} <span className='currency_code'>{currencySymbol?.code}</span></h4>
                            </div>


                        </div>
                        {/* <!--------left-protfolio----> */}
                    </div>


                    <div className="col-lg-4 mt-3 img-w-titel mx-auto">

                        <div class="mid-protfolio text-center position-relative h-100">
                            <div class="position-relative img-w-titelbx">
                                <img src="assets/image/mainttd.svg" alt="" class="img-fluid w-75" />
                                <div>
                                    <h1 style={{ top: "33%", fontSize: "1.2rem", textTransform: "capitalize" }}>Balance</h1>
                                </div>
                                <div class="count">
                                    <h1>{currencySymbol.symbol} {Number((balanceDetails?.currentBalance || 0) + (showData?.balanceinShares || 0) + profit).toFixed(2)} <span className='currency_code'>{currencySymbol.code}</span></h1>
                                </div>
                                <div class=" count">
                                    <h1 style={{ top: "67%" }}>Profit</h1>
                                </div>
                                <div class=" count">
                                    <h1 style={{ top: "80%" }}>{currencySymbol.symbol} {profit.toFixed(2)} <span className='currency_code'>{currencySymbol.code}</span></h1>
                                </div>
                            </div>






                         </div>


                        {/* <div className="mid-protfolio">
                            <img src="assets/image/circle-img.png" className="img-fluid mx-auto d-block" alt='' />
                      

                            <div className="img-w-titelbx">
                                <h1>Current Balance</h1>
                            </div>

                            <div className="img-w-titelbx1 count">
                                <h1 >{currencySymbol} {balanceDetails?.currentBalance||0}</h1>
                            </div>

                            <div className="img-w-titelbx2 count">
                                <h1>Profit</h1>
                            </div>

                            <div className="img-w-titelbx3 count">
                                <h1>{currencySymbol} {profit.toFixed(2)}</h1>
                            </div>


                            <div className="d-grid gap-2">
                                
                                    <button className="btn bt-light fullc-or mt-4 mb-3 openTradeStep" type="button" id='open-Trade-click' data-bs-toggle="modal" onClick={() => setOpenTradeRun(true)} data-bs-target="#openTrade">Open Trade <span> <i className="bi bi-plus-lg"></i> </span></button>
                            

                                <button className="btn bt-drk calculatorStep" type="button" data-bs-toggle="modal" data-bs-target="#calculator">Trade Calculator</button>
                            </div>

                        </div> */}
                    </div>

                    <div className="col-lg-3">
                        <div className="two-selctone">
                            {/* <!--------right-protfolio----> */}
                            <h4 className="pb-1">Portfolio</h4>

                            <select className="form-select time_port mb-2" value={portFolioId} aria-label="form-select-lg example" onChange={handleChangePortFolio}>
                                {
                                    portfololioList?.map((val, ind) => {
                                        return (
                                            <option key={ind} value={val.portfolioId}>{val.portfolioName} </option>
                                        )
                                    })
                                }
                            </select>

                            <div className="form-check form-switch  mb-3">
                                <input className="form-check-input" checked={helpModeActivated} type="checkbox" id="flexSwitchCheckDefault" onClick={handleClickStart} />
                                <span className="form-check-label" for="flexSwitchCheckDefault">Help mode</span>
                            </div>
                            {/* <!--------two-selctone----> */}
                            <div className="Balance-bx">
                                <p className='mb-0'>Balance in Margin</p>
                                <h4 className='herofont'>{currencySymbol.symbol} {Number(balanceinCFD).toFixed(2)} <span className='currency_code'>{currencySymbol.code}</span></h4>
                            </div>

                            <div className="Balance-bx mt-3 mb-3">
                                <p className='mb-0'>Leverage</p>
                                <h4 className='herofont'>{leverage.toFixed(2)}%</h4>
                            </div>

                           

                            


                        </div>
                        {/* <!--------right-protfolio----> */}
                    </div>


                </div>
                <div className="row">
                    <div className="col-lg-3 ">
                        <div class="d-grid">
                            <button className="btn bt-drk mb-2 portfolioStep" type="button" data-bs-toggle="modal" data-bs-target="#portfolioTable">Portfolio</button>
                            <button className="btn bt-drk mb-2 calculatorStep" type="button" data-bs-toggle="modal" data-bs-target="#calculator">Trade Calculator</button>
                            <Link to={"/stock-screener"}>

                                <button className="btn bt-drk mb-2 w-100 calculatorStep" type="button">Stock Screener</button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                        <div class="d-grid">
                            <button className="btn bt-light fullc-or mb-2 tradeStep" type="button" onClick={handleNavigatetrade}>Trade Recommendations</button>
                            <button className="btn bt-drk mb-2 openTradeStep" type="button" id='open-Trade-click' data-bs-toggle="modal" onClick={() => setOpenTradeRun(true)} data-bs-target="#openTrade">Open Trade <span> <i className="bi bi-plus-lg"></i> </span></button>
                            <button className="btn bt-drk mb-2 openTradeStep" type="button" id='Video-play-click' data-bs-toggle="modal" onClick={() => setPlayVideo(true)} data-bs-target="#Videoplayclick">Help Videos</button>
                        </div>
                    </div>
                    <div className="col-lg-3 ">
                        <div class="d-grid">
                            <button className="btn bt-drk mb-2 accountStep" type="button" data-bs-toggle="modal" data-bs-target="#manageAccount">Manage Account</button>
                            <button className="btn mb-2 bt-drk" type="button" data-bs-toggle="modal" data-bs-target="#watchList">WatchList</button>
                            {userType.role === "user" ? (
                                <button className="btn bt-drk mb-2 calculatorStep" type="button" data-bs-toggle="modal" data-bs-target="#createStrategy">Strategy{" "}</button>

                            ) : (
                                <Link to={"/admin"}>
                                    <button className="btn bt-drk mb-2 calculatorStep w-100" type="button">
                                        Admin
                                    </button>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Hero